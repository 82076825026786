import React from "react";
import { InputWrapper, InputLabel } from '../style';


const CustomInput = (props) => {

    const { form, type, handleInput, name, showPassword, handlePassword, width, required } = props



    return (
        <React.Fragment>
            {(form.value.length > 0) && <InputLabel>{name}</InputLabel>}
            <InputWrapper width={width} register error={ (form.touched && (form.value).trim().length === 0) && type !== 'flatNr'} style={{marginTop:10, flexDirection: "row" }}>
                <input
                    placeholder={required ? `${name}* ` : name}
                    onChange={e => handleInput(type, e.target.value)}
                    style={{ paddingLeft: 10, width: "100%" }}
                    type={type === "password" ? "password" : "text"}
                    required={required ? required : false}
                    value={form.value}
                    maxLength={type === "pcode" ? "6": "100"}
                ></input>
                {type === 'password' && 
                <React.Fragment>
                {!showPassword ?
                            <ion-icon name="eye" onClick={() => { handlePassword() }}></ion-icon> :
                            <ion-icon name="eye-off" onClick={() => { handlePassword() }}></ion-icon>}
                    </React.Fragment>}
            </InputWrapper>
            {(form.touched && (form.value).trim().length === 0) && type !== 'flatNr' && <p style={{paddingLeft:20, color:"red"}}>Pole jest wymagane</p>}
        </React.Fragment>
    )
}

export default CustomInput