import styled from 'styled-components';

export const H1 = styled.h1`
color: ${props => props.color ? props.color : "#95a5a6"};
fontWeight: bold;
margin:${props => props.marginH ? props.marginH : 10}px;
font-size:${props => props.size ? props.size : 20}px;
padding-left:5px;
`

export const SearchButton = styled.a`
display:flex;
flex-direction:row;
align-items:center;
border-radius: 15px;
border: 1px solid #ecf0f1;
padding:5px;
position:fixed;
top:10px;
left:10px;
right:10px;
background:white;
z-index:9996;
`
export const SearchInput = styled.div`
display:flex;
flex-direction:row;
align-items:center;
backgroundColor: white;
border-color: #ecf0f1;
border-width: 1px;
border-radius: 15px;
margin-left:15px;
`

export const Days = styled.div`
border: 1px solid #95a5a6;
border-radius: 10px;
padding: 5px;
margin: 10px 2px;
text-align:center;
color: ${props => props.active ? "#fff" : '#95a5a6'};
background: ${props => props.active ? "#0260b296" : '#fff'};
p {
    width:30px;
}
`

export const HoursSelect = styled.div`
width: 30%;
border:  ${props => props.active ? "3px solid #0260b296" : '1px solid #95a5a6'};
border-radius: 10px;
text-align: center; 
padding: 2px 0; 
margin: 5px 0;
opacity: ${props => props.not ? "0.5" : "1"}
`

export const PromoDiv = styled.div`
display: flex; 
padding: 10px;
alignItems: center;
opacity: ${props => props.logged ? "1" : '0.5'};
input {
    width: 60%; 
    borderRadius: 15px;
    border: none;
    background: #f4f4f4; 
    padding: 15px; 
    marginRigth: 10px; 
    outline: none;
}
`

export const PaymentOption = styled.div`
background:  ${props => props.pay ? "#fff" : 'var(--background)'};
opacity: ${props => props.pay ? "1" : '0.5'};
display: flex;
padding: 10px 10px; 
font-size: 20px;
border-radius:15px;
margin: 5px 0;
cursor: pointer;
color: ${props => props.selected ? "#666" : '#666'};
border: ${props => props.selected ? "3px solid #0260b296" : '1px solid #666'};
p{
    margin-left:10px;
}
`
export const OrderButton = styled.div`
display:flex;
flex-direction: "row";
align-items: center;
border-radius: 15px; 
border: 1px solid #fff; 
padding: 3px 7px; 
margin-right: 10px; 
background: #fff;
opacity: ${props => props.minDelivery && !props.error ? "1" : '0.5'};
svg{
    width: 24px; 
    height: 24px; 
}
p {
    color: rgb(0, 84, 157);
    display: flex;
    align-items: center;
}
`