import React, { useState, useEffect } from "react";
import { H2, ButtonSmall, P } from "../user/style";
import { HoursSelect, Days, PaymentOption, PromoDiv } from "./style";
import CustomInput from "../user/components/custominput";
import { useNavigate } from "react-router-dom";
import { Axios, storeData } from "../tools/axios";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../assets/images/spinner.gif"
import Modal from "../modal/modal";
import EditAddress from "../user/editAddress";
import AddressList from "../user/addressList";
import { Btn } from "../user/style";
import { setActiveAddress, priceUpdate } from "../redux/generalSlice";

const Form = () => {

    const [form, setForm] = useState({
        person: {
            value: '',
            error: true,
            touched: false,
        },
        phone: {
            value: '',
            error: true,
            touched: false,
        },
        pcode: {
            value: '',
            error: true,
            touched: false,
        },
        city: {
            value: '',
            error: true,
            touched: false,
        },
        street: {
            value: '',
            error: true,
            touched: false,
        },
        homeNr: {
            value: '',
            error: true,
            touched: false,
        },
        flatNr: {
            value: '',
            touched: false,
        },
        instructions: {
            value: '',
        },
        email: {
            value: '',
            error: true,
            touched: false,
        }
    })

    let dispatch = useDispatch();


    const [reservations, setReservations] = useState({})
    const [hours, setHours] = useState([])
    const [active, setActive] = useState({
        'day': 0,
        'hour': 0
    })
    const [address, setAddress] = useState({})
    const [mounted, setMounted] = useState(false)
    const [madeReservation, setMadeReservation] = useState({})
    const [section, setSection] = useState({
        'address': true,
        'reservation': false,
        'code': false,
        'final': false,
    })
    const [promo, setPromo] = useState('')
    const [validPromo, setValidPromo] = useState('')
    const [promoOK, setPromoOK] = useState(false)
    const [pay, setPay] = useState(true)
    
    let navigate = useNavigate();
    const fullPrice = useSelector(state => state.general.totalPrice)
    const logged = useSelector(state => state.general.isLogged)
    const activeAddress = useSelector(state => state.general.activeAddress)
    const [payment, setPayment] = useState(0)
    const [notAvalaible, setNotAvalaible] = useState('')
    const [info, setInfo] = useState('')

    const [show, setShow] = useState(false)
    const [modalText, setModalText] = useState('')
    const [view, setView] = useState({
        'my': false,
        'edit': false,
    })
    const [formData, setFormData] = useState({})
    const [formId, setFormId] = useState('')
    const [refresh, setRefresh] = useState(false)

    useEffect(() => {
        if (!mounted) {
            Axios.get('mzmapp/reservations').then((response) => {
                const newData = response.data
                setReservations(response.data.reservations_active)
                if (newData.reservation.date) {
                    setMadeReservation(newData.reservation)
                    Object.keys(response.data.reservations_active).map(r => {
                        if (r === newData.reservation.delivery_date_date) {
                            setHours(response.data.reservations_active[r])
                            Object.keys(response.data.reservations_active[r]).map(sh => {
                                if ((response.data.reservations_active[r])[sh].delivery_date_hour_string === newData.reservation.delivery_date_hour_string) {
                                    setActive({
                                        ...active,
                                        'day': newData.reservation.delivery_date_date,
                                        'hour': (response.data.reservations_active[r])[sh].id
                                    })
                                }
                            })
                        }
                    })
                }

                if (newData.cart.info_html) {
                    setInfo(newData.cart.info_html)
                }
                setPay(newData.cart.can_pay)
                const token = newData.token
                storeData(`${token}`)
            }).catch(err => { console.log(err) })
            if (logged) { setPayment(1) }

            Axios.get('mzmapp/address').then((response) => {
                const res = response.data.address
                setAddress(res)
            }).catch(err => { console.log(err) })
            setMounted(true)
        }
    }, [mounted])

    const handlePostCode = () => {
        let pcodeSplit = form.pcode.value.split('')
        console.log()
        // var $val = $t.val(); // pobieranie
        //             var $vspb = $val.split('');
        //             $val = $val.replace(/[^0-9]+/g, "");
        //             var $vsp = $val.split('');
        //             $val='';
        //             for (step = 0; step < 2; step++) {
        //                 if($vsp[step]){
        //                     $val=$val+''+$vsp[step];
        //                 }
        //             }
        //             if($vspb[2]=='-'){
        //                 $val=$val+'-';
        //             }else{
        //                 if($vsp.length>2){
        //                     $val=$val+'-';
        //                 }
        //             }
        //             for (step = 2; step < 5; step++) {
        //                 if($vsp[step]){
        //                     $val=$val+''+$vsp[step];
        //                 }
        //             }
        //             $t.val($val); // zamienianie
        }

    const handleInput = (inputType, text) => {
        
        setForm({ ...form, [inputType]: { value: text, error: false, touched: true } })
    }
    const handleAddAddress = () => {
        let val = 0

        for (const key in form) {
            
            if (logged && form[key].error === true && form[key].value === '' && key !== 'email') {
                val++
                form[key].touched = true
                setModalText('Wszystkie pola z gwiazdką są wymagane')
                setShow(true)
            } else if (!logged && form[key].error === true && form[key].value === '') {
                val++
                form[key].touched = true
                setModalText('Wszystkie pola z gwiazdką są wymagane')
                setShow(true)
            }
        }

        let AdressData = { }

        if (!logged) {
            AdressData = { "reservation_id": "1", "postcode": form.pcode.value, "name": form.person.value, "phone": form.phone.value, "city": form.city.value, "street": form.street.value, "street_no": form.homeNr.value, "street_no2": form.flatNr.value, "notices": form.instructions.value, "email": form.email.value }
        } else {
            AdressData = { "reservation_id": "1", "postcode": form.pcode.value, "name": form.person.value, "phone": form.phone.value, "city": form.city.value, "street": form.street.value, "street_no": form.homeNr.value, "street_no2": form.flatNr.value, "notices": form.instructions.value}
        }


        if (val === 0) {
            Axios.post('mzmapp/addressadd', AdressData).then((response) => {
                const res = response.data
                const token = res.token
                storeData(`${token}`)

                if (res.err) {
                    setModalText(res.err[Object.keys(res.err)])
                    setShow(true)
                }
                Axios.get('mzmapp/address').then((response) => {
                    const res = response.data.address
                    setAddress(res)
                    setRefresh(!refresh)
                    dispatch(setActiveAddress(response.data.address[Object.keys(response.data.address)[0]].id))
                }).catch(err => { console.log(err) })
            }).catch(err => { console.log(err) })
        }
    }


    const handleHours = (date) => {
        setActive({ ...active, 'day': date })

        let timeFrame = []
        for (const property in reservations) {
            if (date === property) {
                timeFrame.push(reservations[property])
            }
        }
        let h = []
        for (const key in timeFrame) {
            setHours(timeFrame[key])
        }
    }

    const selectedHour = (hour) => {
        setActive({ ...active, 'hour': hour.id })
    }

    const Next = (next, before) => {
        setSection({ ...section, [next]: true, [before]: false })
    }

    const Back = (current, before) => {
        setSection({ ...section, [current]: false, [before]: true })
    }

    const handleReservation = () => {
        let id
        if (activeAddress !== null) { id = activeAddress }
        else {
            if (Object.keys(address).length > 0) {
                id = address[Object.keys(address)[0]].id
                dispatch(setActiveAddress(address[Object.keys(address)[0]].id))
                console.log(address[Object.keys(address)[0]].id)
            }
        }

        console.log(activeAddress)

        if (Object.keys(address).length === 0) {
            setModalText('Dodaj adres dostawy')
            setShow(true)
        } else if (active.hour === 0) {
            setModalText('Wybierz dzień i godzinę')
            setShow(true)
        } else if ((Object.keys(madeReservation)).length > 0 && active.day === madeReservation.delivery_date_date) {
            setSection({ ...section, 'address': false, 'reservation': true })
        } else if (Object.keys(address).length > 0 && active.hour !== 0) {
            let addressData = { "address_id": id, "reservation_id": 1 }
            Axios.post('mzmapp/addressset', addressData).then((response) => {
            }).catch(err => { console.log(err) })
            let reservationData = { "id": active.hour, "reservation_id": "1" }
            Axios.post('mzmapp/ordersetreservation', reservationData).then((response) => {
                const newData = response.data
                if (newData.cart.info_html) { setInfo(newData.cart.info_html) }
                setPay(newData.cart.can_pay)
                setMadeReservation(response.data.reservation)
                if (newData.err) {
                    console.log(newData.err)
                    setNotAvalaible(active.hour)
                    setModalText(newData.err[Object.keys(newData.err)])
                    setShow(true)
                } else if (newData.cart.rows_errors && Object.keys(newData.cart.rows_errors).length > 0) {
                    navigate('/cart')
                } else {
                    setSection({ ...section, 'address': false, 'reservation': true })
                }
            }).catch(err => { console.log(err) })
        }
    }

    const handleRelease = (current, before) => {
        Axios.post('mzmapp/cancelordersetreservation', { "reservation_id": "1" }).then((response) => {
            const newData = response.data
            // dispatch(activeAddress(''))
        }).catch(err => { console.log(err) })
        Back(current, before)
    }


    const handlePromoCode = () => {
        if (!logged || promoOK) return
        Axios.post('mzmapp/orderssetdiscount', { "code": promo, "reservation_id": "1" }).then((response) => {
            const res = response.data
            if (res.ok === 1) {
                setModalText('Kod dodany')
                setShow(true)
                setPromoOK(true)
                dispatch(priceUpdate(parseFloat(res.cart.vcosts_all)))
            }
            if (res.err) { setValidPromo(res.err.code) }
        }).catch(err => { console.log(err) })
    }

    const handlePayment = () => {

        if (pay) {
            Axios.post('mzmapp/orderscreate', { "reservation_id": 1, "pay_on_delivery": payment }).then((response) => {
                const res = response.data
                if (!res.err) {
                    if (payment === 0 && res.redirect_url !== undefined) {
                        window.location.href = `${res.redirect_url}`
                    } else if (payment === 1) {
                        setModalText('Dziekujemy za zakupy')
                        setShow(true)
                    }
                }
            }).catch(err => { console.log(err) })
        }
    }

    const showModal = () => {
        setShow(!show)
        if (modalText === 'Dziekujemy za zakupy') {
            navigate('/home')
        }
    }
    const handleClose = (screen) => {
        setView({ ...view, [screen]: false, })
        setRefresh(!refresh)
    }

    const handleView = (screen) => {
        if (screen === 'edit') {
            Object.keys(address).map(actual => {
                if (address[actual].id === activeAddress) {
                    setFormData(address[actual])
                    setFormId(activeAddress)
                } else {
                    setFormData(address[Object.keys(address)[0]])
                    setFormId(address[Object.keys(address)[0]].id)
                }
            })
        }
        setView({ ...view, [screen]: true, })
    }

    return (
        <React.Fragment>
            {view.edit && <EditAddress formData={formData} id={formId} handleClose={handleClose} title={'Edytuj adres'} fromForm={true}/>}
            {view.my && <AddressList order OrderHandleClose={handleClose} fromForm={true} />}
            <React.Fragment>
                {show && <Modal text={modalText} showModal={showModal} />}
                <div style={{ width: "100%" }}>
                    {section.address && <section id="1">
                        <div onClick={() => { navigate('/cart') }}
                            style={{ display: "flex", alignContent: "center", background: "#00549D", color: "#fff", margin: "0", padding: "10px 0" }}>
                            <ion-icon name="chevron-back" style={{ marginLeft: 10, color: "#fff" }}
                            ></ion-icon>Adres dostawy</div>
                        <div style={{ position: "relative", borderTopWidth: 2, borderTopColor: "#ecf0f1", backgroundColor: "#fff" }}>
                            <H2 color={"#00549D"} style={{ fontSize: 20, margin: "0px", paddingTop: 10, paddingLeft: 10 }}>Adres dostawy</H2>
                            <React.Fragment>
                                {!mounted ?
                                    <div style={{ background: "#fff", display: "flex", justifyContent: "center" }}>
                                        <img src={Spinner} style={{ width: 40, height: 40 }}></img>
                                    </div> :
                                    <div>
                                        {Object.keys(address).length == 0 ?
                                            <div style={{ position: "relative", borderBottomWidth: 2, borderBottomColor: "#ecf0f1", marginHorizontal: 5 }}>
                                                <CustomInput form={form.person} type={"person"} name={logged ? "Adresat" : "Imię i nazwisko"} handleInput={handleInput} required />
                                               {!logged && <CustomInput form={form.email} type={"email"} name={"Email"} handleInput={handleInput} required />}
                                                <CustomInput form={form.phone} type={"phone"} name={"Telefon"} handleInput={handleInput} required />
                                                <CustomInput form={form.pcode} type={"pcode"} name={"Kod pocztowy"} handleInput={handleInput} required />
                                                <CustomInput form={form.city} type={"city"} name={"Miasto"} handleInput={handleInput} required />
                                                <CustomInput form={form.street} type={"street"} name={"Ulica"} handleInput={handleInput} required />
                                                <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                                                    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                                        <CustomInput form={form.homeNr} type={"homeNr"} name={"Nr budynku"} handleInput={handleInput} required />
                                                    </div>
                                                    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                                        <CustomInput form={form.flatNr} type={"flatNr"} name={"Nr lokalu"} handleInput={handleInput} />
                                                    </div>
                                                </div>
                                                <CustomInput form={form.instructions} type={"instructions"} name={"Wskazówki dla kierowcy"} handleInput={handleInput} />

                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", paddingBottom: 60, paddingLeft: 10, alignContent: "flex-start", borderBottom: "1px solid #ecf0f1", }}>
                                                    <ion-icon name="information-circle-outline" style={{ color: "#00549D", fontSize: "24px", marginTop: 10 }}></ion-icon>
                                                    <P style={{ color: "#95a5a6", fontSize: 12, margin: 0, padding: 10 }}>Sugestie dotyczące parkowania, dojazdu do domu (jeśli jest nietypowy), korzystania z domofonu itp.</P>
                                                </div>
                                                <div style={{ display: "flex", position: "relative", justifyContent: "center", alignItems: "center", paddingVertical: 15 }}>
                                                    <ButtonSmall

                                                        style={{ bottom: "-20px", color: "#fff", textAlign: "center" }}
                                                        onClick={() => { handleAddAddress() }}>
                                                        <p>Dodaj adres dostawy</p>
                                                    </ButtonSmall>
                                                </div>
                                            </div> :
                                            <div style={{ padding: 10 }}>
                                                {Object.keys(address).length > 0 &&
                                                    <div style={{ display: "flex", border: "1px solid green", flexDirection: "column", borderRadius: 10, padding: "10px 10px" }}>
                                                        {Object.keys(address).map(actual => {
                                                            if (address[actual].id === activeAddress) {
                                                                return (
                                                                    <div key={address[actual].id}>
                                                                        <p style={{ fontWeight: "bold" }}>{address[actual].name}</p>
                                                                        <p>ul. {address[actual].street} {address[actual].street_no}{address[actual].street_no2 && "/" + address[actual].street_no2}</p>
                                                                        <p>{address[actual].postcode + ' ' + address[actual].city}</p>
                                                                    </div>
                                                                )
                                                            }
                                                        })}
                                                        {Object.keys(address).length > 0 && activeAddress === null &&

                                                            <React.Fragment>
                                                                <div>
                                                                    <p style={{ fontWeight: "bold" }}>{address[Object.keys(address)[0]].name}</p>
                                                                    <p>ul. {address[Object.keys(address)[0]].street + ' ' + address[Object.keys(address)[0]].street_no}{address[Object.keys(address)[0]].street_no2 && "/" + address[Object.keys(address)[0]].street_no2}</p>
                                                                    <p>{address[Object.keys(address)[0]].postcode + ' ' + address[Object.keys(address)[0]].city}</p>
                                                                </div>
                                                            </React.Fragment>
                                                        }
                                                    </div>}
                                                <div className="buttonsWrap" style={{ display: "flex", justifyContent: "space-between", padding: "10px 0" }}>
                                                    <Btn
                                                        onClick={() => { handleView('edit') }}
                                                        bgColor={"#fff"}>Edytuj adres</Btn>
                                                    <Btn
                                                        onClick={() => { handleView('my') }}
                                                        color={"#fff"}>Moje adresy</Btn>
                                                </div>
                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", paddingLeft: 10, alignItems: "center", borderBottom: "1px solid #ecf0f1", }}>
                                                    <ion-icon name="information-circle-outline" style={{ color: "#00549D", fontSize: "24px" }}></ion-icon>
                                                    <div>
                                                        <P style={{ color: "#95a5a6", fontSize: 12 }}>Pamiętaj, że w jednym czasie możesz mieć jedynie aktywną jedną rezerwację.</P>
                                                        <P style={{ color: "#95a5a6", fontSize: 12 }}>Wybranie innego terminu dostawy spowoduje anulowanie wcześniejszej rezerwacji</P>
                                                    </div>
                                                </div>
                                            </div>}
                                    </div>}
                            </React.Fragment>
                        </div>

                        <div style={{ paddingTop: 20, background: "#fff", paddingBottom: 100 }}>
                            <H2 color={"#00549D"} style={{ fontSize: 20 }}>Wybierz termin dostawy</H2>
                            {mounted &&
                                <>
                                    {Object.keys(reservations).length === 0 ?
                                        <div style={{ background: "#fff", display: "flex", justifyContent: "center" }}>
                                            <img src={Spinner} style={{ width: 40, height: 40 }}></img>
                                        </div> :
                                        <div style={{ display: "flex", flexDirection: "row", overflowX: "auto", paddingLeft: 10 }}>
                                            {Object.keys(reservations).map(date => {
                                                var day = date.split('-');
                                                var options = {
                                                    weekday: "short",
                                                    year: "numeric",
                                                    month: "2-digit",
                                                    day: "numeric"
                                                };
                                                let da = new Date(date).toLocaleDateString("pl", options)
                                                let weekday = da.split('.')
                                                return (
                                                    <Days active={active.day === date} key={date} onClick={() => { handleHours(date) }}
                                                    >{day[2]}
                                                        <p>{weekday[0]}</p></Days>
                                                )
                                            })}
                                        </div>}
                                </>}
                            {mounted &&
                                <>
                                    <div style={{
                                        display: "flex", flexDirection: "row", overflowX: "auto", padding: "10px", flexWrap: "wrap",
                                        justifyContent: "space-evenly"
                                    }}>
                                        {Object.keys(hours).map(hour => {
                                            return (
                                                <HoursSelect active={active.hour === hours[hour].id} key={hours[hour].id} onClick={() => selectedHour(hours[hour])}>
                                                    {hours[hour].delivery_date_hour_string}
                                                </HoursSelect>
                                                // not={hours[hour].id === notAvalaible}
                                            )
                                        })}
                                    </div>
                                </>}

                            <div style={{ position: "relative", padding: 20, display: "flex", justifyContent: "center", textAlign: "center" }}>
                                <ButtonSmall
                                    onClick={() => { handleReservation() }}
                                    color={"#fff"}
                                    bgColor={Object.keys(address).length > 0 && active.hour !== 0 ? "#00549D" : "var(--borderGrey)"}
                                >Dalej</ButtonSmall>
                            </div>
                        </div>
                    </section>}

                    {section.reservation === true && <section id="2">
                        <div onClick={() => Back('reservation', 'address')}
                            style={{ display: "flex", alignContent: "center", background: "#00549D", color: "#fff", margin: "0", padding: "10px 0" }}>
                            <ion-icon name="chevron-back" style={{ marginLeft: 10, color: "#fff" }}
                            ></ion-icon>Rezerwacja</div>
                        <div style={{ padding: 10, background: "#fff" }}>
                            {Object.keys(madeReservation).length === 0 ?
                                <div style={{ background: "#fff", display: "flex", justifyContent: "center" }}>
                                    <img src={Spinner} style={{ width: 40, height: 40 }}></img>
                                </div> :
                                <div style={{ display: "flex", border: "1px solid green", flexDirection: "column", borderRadius: 10, padding: "10px 10px" }}>
                                    {/* {Object.keys(madeReservation).map(r => {
                                        console.log(madeReservation)
                                        return (
                                            <React.Fragment key={r}>
                                                <p>Termin dostawy</p>
                                                <p style={{ fontWeight: "bold" }}>{madeReservation[r].delivery_date_string}</p>
                                                <p></p>
                                                <p></p>
                                            </React.Fragment>
                                        )
                                    })} */}
                                    <React.Fragment>
                                        <p>Termin dostawy</p>
                                        <p style={{ fontWeight: "bold" }}>{madeReservation.date}</p>
                                        <p></p>
                                        <p></p>
                                    </React.Fragment>
                                    <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-evenly", paddingTop: 20 }}>
                                        <div onClick={() => { handleRelease('reservation', 'address') }}>zwolnij</div>
                                        <div onClick={() => { Back('reservation', 'address') }}
                                        >edytuj</div>
                                    </div>
                                </div>}
                        </div>
                    </section>}
                    {section.code &&
                        <section name="code">
                            <div onClick={() => Back('code', 'reservation')}
                                style={{ display: "flex", alignContent: "center", background: "#00549D", color: "#fff", margin: "0", padding: "10px 0" }}>
                                <ion-icon name="chevron-back" style={{ marginLeft: 10, color: "#fff" }}
                                ></ion-icon>Kod rabatowy</div>
                            <div style={{ background: "#fff", paddingTop: "20px" }}>
                                <H2 color={"#00549D"} style={{ fontSize: 20, margin: "0px 10px" }}>Kod rabatowy</H2>
                                <PromoDiv logged={logged}>
                                    <input
                                        onChange={(e) => { setPromo(e.target.value) }}
                                        value={promo}
                                        placeholder="Podaj kod rabatowy"
                                        disabled={promoOK || !logged}></input>
                                    <div
                                        onClick={() => { handlePromoCode() }}
                                        style={{ borderRadius: 20, background: "#00549D", color: "#fff", padding: 15 }}
                                    >Użyj kodu</div>
                                </PromoDiv>
                                {!promoOK && validPromo && <p style={{ padding: "0 0 10px 10px", color: "red" }}>{validPromo}</p>}
                                {!logged && <p style={{ padding: "0 0 10px 10px" }}>opcja dostępna tylko dla zalogowanych</p>}
                            </div>
                        </section>}

                    {section.final &&
                        <section name="final">
                            <div onClick={() => Back('final', 'code')}
                                style={{ display: "flex", alignContent: "center", background: "#00549D", color: "#fff", margin: "0", padding: "10px 0" }}>
                                <ion-icon name="chevron-back" style={{ marginLeft: 10, color: "#fff" }}
                                ></ion-icon>Finalizacja</div>
                            <div style={{ background: "#fff", paddingTop: "20px", marginTop: "20px" }}>
                                <H2 color={"#00549D"} style={{ fontSize: 20, margin: "0px 10px" }}>Informacje o dostawie</H2>
                                {Object.keys(address).length === 0 ?
                                    <div style={{ background: "#fff", display: "flex", justifyContent: "center" }}>
                                        <img src={Spinner} style={{ width: 40, height: 40 }}></img>
                                    </div> :
                                    <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                                        <div style={{ display: "flex", width: "100%", justifyContent: "space-between", padding: "10px 0", color: "#95a5a6" }}>
                                            <p style={{ padding: "0 10px" }}>Adres dostawy</p>
                                            <div style={{ padding: "0 10px" }}>
                                                {Object.keys(address).length > 0 &&
                                                    <>
                                                        {Object.keys(address).map(actual => {
                                                            if (address[actual].id === activeAddress) {
                                                                return (
                                                                    <div key={address[actual].id} style={{ display: "flex", flexDirection: "column", borderRadius: 10, alignItems: "flex-end" }}>
                                                                        <p style={{ fontWeight: "bold" }}>{address[actual].name}</p>
                                                                        <p>ul. {address[actual].street + ' ' + address[actual].street_no}{address[actual].street_no2 && '/' + address[actual].street_no2} </p>
                                                                        <p>{address[actual].postcode + ' ' + address[actual].city}</p>
                                                                    </div>)
                                                            }
                                                        })}</>}
                                            </div>
                                        </div>
                                        <div style={{ display: "flex", width: "100%", justifyContent: "space-between", padding: "10px 0", color: "#95a5a6", borderBottom: "1px solid #95a5a6" }}>
                                            <p style={{ padding: "0 10px" }}>Data realizacji</p>
                                            <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end", padding: "0 10px" }}>
                                                <p>{madeReservation.delivery_date_date}</p>
                                                <p>{madeReservation.delivery_date_hour_string}</p>
                                            </div>
                                        </div>
                                    </div>}
                            </div>
                            <div style={{ background: "#fff", marginTop: "20px", paddingTop: "20px", borderBottom: "1px solid #95a5a6" }}>
                                <H2 color={"#00549D"} style={{ fontSize: 20, margin: "0px 10px" }}>Sposób płatności</H2>
                                <div style={{ display: "flex", padding: 10, flexDirection: "column" }}>
                                    {info && <div style={{ paddingBottom: 10 }} dangerouslySetInnerHTML={{ __html: info }} />}
                                    {logged && <PaymentOption
                                        onClick={() => { setPayment(1) }}
                                        pay={pay}
                                        selected={payment === 1 && pay}>
                                        <ion-icon name="card-outline"></ion-icon><p>Kartą płatniczą przy odbiorze</p>
                                    </PaymentOption>}
                                    <PaymentOption
                                        onClick={() => { setPayment(0) }}
                                        pay={pay}
                                        selected={payment === 0 && pay}>
                                        <ion-icon name="laptop-outline"></ion-icon><p>Płatność online</p>
                                    </PaymentOption>
                                </div>
                            </div>
                        </section>}
                </div>

                <div style={{ position: "fixed", bottom: 0, background: "#fff", left: 0, right: 0, width: "100%", padding: "20px 0 0 0" }}>
                    {section.reservation &&
                        <div
                            style={{ margin: "0 auto", borderRadius: 20, background: "#00549D", color: "#fff", padding: 10, width: "150px", textAlign: "center" }}
                            onClick={() => { Next('code', 'reservation') }}
                        >Dalej</div>}
                    {section.code &&
                        <div
                            style={{ margin: "0 auto", borderRadius: 20, background: "#00549D", color: "#fff", padding: 10, width: "150px", textAlign: "center" }}
                            onClick={() => { Next('final', 'code') }}
                        >Dalej</div>}
                    {section.final &&
                        <div
                            style={{ margin: "0 auto", borderRadius: 20, background: "#00549D", color: "#fff", padding: 10, width: "150px", textAlign: "center" }}
                            onClick={() => { handlePayment() }}
                        >{payment === 0 ? 'zapłać' : 'zamów'}</div>}
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <H2>Do zapłaty</H2> <H2 color={"#00549D"}>{fullPrice}zł</H2>
                    </div>
                </div>
            </React.Fragment>
        </React.Fragment>
    )
}

export default Form