import React, { useState } from "react";

import { Axios, storeData } from "../tools/axios";
import CustomInput from "./components/custominput";
import { H1, ButtonSmall } from "./style";
import Logo from "../../assets/images/logo.svg"
import Modal from "../modal/modal";


const Remind = () => {

    const [form, setForm] = useState(
        {
            email: {
                value: '',
                error: false,
                touched: false,
            }
        })
    const [show, setShow] = useState(false)
    const [modalText, setModalText] = useState('')

    const showModal = () => {
        setShow(!show)
    }

    const handleInput = (inputType, text) => {
        setForm({ ...form, [inputType]: { value: text, error: false, touched: true } })
    }

    const handleRemind = () => {
        const data = { email: form.email.value }

        if (form.email.value.length > 1 && form.email.error === false) {
            Axios.post('mzmapp/remind', data).then((response) => {
                const newData = response.data
                if (response.data.ok === 1) {
                    // customAlert(response.data.msg)
                    setModalText(response.data.msg)
                    showModal(true)
                }
                const token = newData.token
                storeData(`${token}`)
            }).catch(err => {
                console.log(err)
            });
        }

    }
    return (
        <React.Fragment>
            {show && <Modal text={modalText} showModal={showModal}/>}
            <div style={{ height: "100%", backgroundColor: "#f4f4f4" }}>
                <div style={{ alignItems: "center", justifyContent: "center", backgroundColor: "#00549D", padding: 10 }}>
                    <img src={Logo} alt="logo" style={{ resizeMode: 'contain', width: 128, height: 44, margin: "0 auto", display: "block" }}
                    ></img></div>
                <div style={{ borderBottom: "1px solid #95a5a6", paddingBottom: 60, marginBottom: 10, backgroundColor: "white" }}>
                    <H1 color={"#00549D"} style={{ padding: "10px 20px", margin: 0 }}>Resetuj hasło</H1>
                    <p style={{ paddingLeft: 20, paddingBottom: 10, color:"#666" }}> Podaj adres e-mail, który użyłeś podczas zakładania konta Anabru</p>
                    <CustomInput form={form.email} type={"email"} name={"Adres email"} handleInput={handleInput} />
                    <div style={{ display: "flex", position: "relative", justifyContent: "center", alignItems: "center", paddingVertical: 15 }}>
                        <ButtonSmall style={{ bottom: "-50px" }} onClick={() => handleRemind()}>
                            <p style={{ color: "#fff", textAlign: "center" }}>Resetuj Hasło</p>
                        </ButtonSmall>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Remind