import React, { useState, useEffect } from "react";
import { Axios } from "../tools/axios";
import { Container, SearchButton, SearchInput, H1 } from "./styles";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { isModalText, isModal } from "../redux/generalSlice";
import ProductSmall from "../product/productSmall";
import Spinner from "../../assets/images/spinner.gif"
import Modal from "../modal/modal";

const ProductsList = () => {

    const [mounted, setMounted] = useState(false)
    const [products, setProducts] = useState({})
    const [subs, setSubs] = useState({})
    const [refresh, setRefresh] = useState(false)
    const [last, setLast] = useState('')
    const [name, setName] = useState('')
    const isModalShow = useSelector(state => state.general.modal)
    const modalTxt = useSelector(state => state.general.modal_text)

    let navigate = useNavigate();
    let location = useLocation();
    let dispatch = useDispatch()

    let id = location.search.split("=")[1]

    useEffect(() => {
        Axios.get(`mzmapp/products?category_id=${id}`).then((response) => {
            const res = response.data.rows
            setProducts(res)
            setMounted(true)
        }).catch(err => { console.log(err) })

        Axios.get('mzmapp/categories').then((response) => {
            // const categoriesData = response.data.rows[location.state.id_main].subs
            if (location.state.last) {
                const categoriesData = response.data.rows[location.state.id_main].subs[location.state.id_sub_main].subs
                setSubs(categoriesData)
            } else if (location.state.id_main) {
                const categoriesData = response.data.rows[location.state.id_main].subs[location.state.id_sub].subs
                setSubs(categoriesData)
            } setMounted(true)
        }).catch(err => { console.log(err) })
        return function cleanup() { setMounted(false) }
    }, [refresh])

    const cats = []
    let list = []

    if (mounted === true) {
        for (const property in products) {
            cats.push(products[property])
        }
        list = cats.sort(function (a, b) {
            return a.pos - b.pos;
        })
    }

    const handleClose = () => {
        dispatch(isModal(false))
    }

    return (
        <React.Fragment>
            {isModalShow && <Modal text={modalTxt} showModal={handleClose} />}
            <React.Fragment>
                <div style={{ display: "flex", flexDirection: "column", background: "white", position: "fixed", width: "100%", zIndex: "9999" }}>
                    <SearchButton onClick={() => navigate('/search')}>
                        <SearchInput>
                            <ion-icon style={{ marginRight: 20 }} name="search-outline"></ion-icon>
                            <p>Czego szukasz?</p>
                        </SearchInput>
                    </SearchButton>
                    <div style={{ display: "flex", marginTop: 90, paddingBottom: 10 }}>
                        <ion-icon name="chevron-back" style={{ marginLeft: 10, color: "#666", fontSize: "20px" }}
                            onClick={() => {
                                if (last === true) {
                                    navigate(`/category/subcategory/products?mc=${location.state.id_main}`, { state: { id_main: location.state.id_main, id_sub: location.state.id_sub_main, name: location.state.name_main, last: false } })
                                    setLast(false)
                                    setRefresh(!refresh)
                                } else { navigate(`/category/subcategory?mc=${location.state.id_main}`) }
                            }}
                        ></ion-icon> <H1 style={{ margin: 0 }}>{location.state.name}</H1>
                    </div>
                </div>

                <Container>
                    <React.Fragment>
                        <div style={{ marginTop: 140 }}>
                            <div style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
                                {!last && Object.keys(subs).map(v => {
                                    if (subs[v].name !== location.state.name) {
                                        return (
                                            <div key={subs[v].name} style={{ height: "30px", border: "1px solid #d8dee2", borderRadius: 10, backgroundColor: "#fff", margin: "0 2px", padding: 5, display: "inline-flex", alignItems: "center" }}
                                                onClick={() => {
                                                    setRefresh(!refresh)
                                                    setLast(true)
                                                    navigate(`/category/subcategory/products?=${subs[v].id}`, { state: { id_main: location.state.id_main, id_sub: subs[v].id, id_sub_main: location.state.id_sub, name_main: location.state.name, name: subs[v].name, last: true } })
                                                }}
                                            >
                                                <p style={{ color: "#00549D", display: "flex", alignItems: "center", fontSize: "16px", width: "100%" }}>{subs[v].name}</p>
                                            </div>)
                                    }
                                })}
                            </div>
                        </div>
                        {!mounted ?
                            <div style={{ background: "#fff", display: "flex", justifyContent: "center", height: "100%", width: "100%", alignItems: "center", marginTop: "60px" }}>
                                <img src={Spinner} style={{ width: "100%", height: "100%", maxWidth: 80, maxHeight: 80 }}></img>
                            </div> :

                            <div style={{ backgroundColor: "#fff", marginTop: 10, overflowY: "hidden", paddingBottom: 80 }}>
                                {Object.keys(list).map(v => {
                                    return (
                                        <ProductSmall key={list[v].id} product={list[v]} />)
                                })}
                            </div>}
                    </React.Fragment>
                </Container>
            </React.Fragment>
        </React.Fragment>
    )
}

export default ProductsList