import React, { Fragment, useState } from 'react'
import { H1 } from './styles'

const InfoCard = (props) => {

    const [show, setShow] = useState(false)

    const { info } = props

    return (
        <div style={{ marginTop: 10, backgroundColor: "#fff", padding:"10px 20px", borderBottom: "1px solid #bdc3c7" }}

        >
            <div style={{ flexDirection: "row", width: "100%" }} >
                <H1 style={{ width: "100%", margin:"0" }} onClick={() => { setShow(!show) }}
                color={"#00549D"} marginH={"0"} size={15}
                >{info.name}</H1>

            </div>
            {show &&
                <div style={{padding:"10px 0", overflowX:"auto"}}>
                    <div dangerouslySetInnerHTML={{__html: info.content}} />
                    {/* <p style={{color:"#666"}}>{info.content}</p> */}
                    
                </div>}


        </div>
    )
}

export default InfoCard