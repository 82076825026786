import React, { useState, useEffect, useRef } from "react";
import { EditWrapper, ListNameInput, ModalOption, Btn } from "./styles";
import { useLocation, useNavigate } from "react-router-dom";
import { Axios } from "../tools/axios";
import ProductSmall from "../product/productSmall"
import { useDispatch, useSelector } from "react-redux";
import { isModal, priceUpdate, isModalText } from "../redux/generalSlice";
import Modal from "../modal/modal"



const List = () => {
    let location = useLocation()
    let navigate = useNavigate()
    let nameInput = useRef(null)
    let dispatch = useDispatch()

    const [items, setItems] = useState({})
    const [mounted, setMounted] = useState(false)
    const [name, setName] = useState('')
    const [edit, setEdit] = useState(false)
    const [information, setInformation] = useState('')
    const [answer, setAnswer] = useState('')
    const [isModalConfirm, setIsModalConfirm] = useState(false)
    const [type, setType] = useState('')

    const showM = useSelector(state => state.general.modal)
    const modalText = useSelector(state => state.general.modal_text)

    const id = location.search.split("=")[1]

    useEffect(() => {
        Axios.get('mzmapp/favlist').then((response) => {
            setItems(response.data.fav_list[id])
            setName(response.data.fav_list[id].name)
            setMounted(true)
        }).catch(err => {
            console.log(err)
        })
    }, [])

    const editName = () => {
        setEdit(true)
        setTimeout(() => {
            nameInput.current.focus();
        }, 200);
    }

    const showModal = (type) => {
        if (type === 'delete') {
            setType('delete')
            setInformation('Czy na pewno chcesz usunąć listę?')
            setIsModalConfirm(true)
        } else if (type === 'change') {
            setType('change')
            setInformation('Czy na pewno chcesz zmienić nazwę?')
            setIsModalConfirm(true)
        }
    }

    const handleOption = (type, option) => {
        if (type === 'delete') {
            if (option === 'yes') {
                Axios.post('mzmapp/favlistremove', { "id": id }).then((response) => {
                    const res = response.data
                    if (res.err) { console.log(res) }
                    dispatch(isModalText('Lista usunięta'))
                    dispatch(isModal(true))
                    setTimeout(() => {
                        navigate('/lists')
                    }, 3500);
                }).catch(err => { console.log(err) })
            }
            setIsModalConfirm(false)
        } else {
            if (option === 'yes') {
                Axios.post('mzmapp/favlistrename', { "id": id, name: name }).then((response) => {
                    const res = response.data
                    setIsModalConfirm(false)
                    if (res.err) { console.log(res) }
                }).catch(err => { console.log(err) })
            } else {
                setName(items.name)
                setIsModalConfirm(false)
            }
        }
    }

    const accept = () => {
        if (edit) {
            setEdit(false)
            showModal('change')
        } else {
            Axios.post('mzmapp/favlistproductaddtocart', { "list_id": id }).then((response) => {
                const res = response.data
                dispatch(isModalText('Produkty dodane do koszyka'))
                dispatch(isModal(true))
                dispatch(priceUpdate(res.cart.vcosts_products))
                setTimeout(() => {
                    navigate('/cart')
                }, 3500);
            }).catch(err => {
                console.log(err)
            })
        }
    }

    const handleClose = () => {
        dispatch(isModal(false))
    }

    return (
        <EditWrapper>
            {showM && <Modal showModal={handleClose} text={modalText} />}
            {mounted &&
                <>
                    <header>
                        <div className={"showList"}>
                            <ion-icon name="chevron-back"
                                onClick={() => { navigate('/lists') }}
                            ></ion-icon>
                            <ListNameInput
                                ref={nameInput}
                                edit={edit}
                                value={name}
                                onChange={(e) => { setName(e.target.value) }}
                                disabled={edit === false}
                            />
                        </div>
                        <div className={"settings"}>
                            <ion-icon onClick={() => editName()} name="create-outline"></ion-icon>
                            <ion-icon onClick={() => { showModal('delete') }} name="trash-bin-outline"></ion-icon>
                        </div>
                    </header>
                    <div style={{ position: "relative", borderBottom: "1px solid #ecf0f1", margin: "10px 0", paddingBottom: 40 }}>
                        {Object.keys(items.rows).map(v => {

                            return (
                                <ProductSmall key={items.rows[v].id} product={items.rows[v]} />)
                        })}
                    </div>
                </>}
            {isModalConfirm && <ConfirmModal info={information} type={type} handleOption={handleOption} />}
            <div className={"bottom"}>
                <div>
                    <Btn onClick={() => { accept() }}>{edit ? 'Zatwierdź' : 'Dodaj do koszyka'}</Btn>
                </div>
            </div>
        </EditWrapper>
    )
}

export default List


const ConfirmModal = (props) => {
    const { info, handleOption, type } = props
    return (
        <ModalOption>
            <div className="modal-body">
                <p>{info}</p>
                <div>
                    <Btn onClick={() => { handleOption(type, 'yes') }}>tak</Btn>
                    <Btn onClick={() => { handleOption(type, 'no') }}>nie</Btn>
                </div>
            </div>
        </ModalOption>
    )
}