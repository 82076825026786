import React, { useState } from "react";
import { EditWrapper } from "./style";
import CustomInput from "./components/custominput";
import { useSelector, useDispatch } from "react-redux";
import { ButtonSmall } from "../user/style";
import { Axios } from "../tools/axios";
import Modal from "../modal/modal";
import { addressesList } from "../redux/generalSlice";


const EditAddress = (props) => {

    const { formData, title, handleClose, id } = props

    let dispatch = useDispatch()
    const [refresh, setRefresh] = useState(false)
    const [showM, setShowM] = useState(false)
    const [modalText, setModalText] = useState('')
    const [form, setForm] = useState({
        person: {
            value: formData.name ? formData.name : '',
            error: true,
            touched: false,
        },
        phone: {
            value: formData.phone ? formData.phone : '',
            error: true,
            touched: false,
        },
        pcode: {
            value: formData.postcode ? formData.postcode : '',
            error: true,
            touched: false,
        },
        city: {
            value: formData.city ? formData.city : '',
            error: true,
            touched: false,
        },
        street: {
            value: formData.street ? formData.street : '',
            error: true,
            touched: false,
        },
        homeNr: {
            value: formData.street_no ? formData.street_no : '',
            error: true,
            touched: false,
        },
        flatNr: {
            value: formData.street_no2 ? formData.street_no2 : '',
            touched: false,
        },
        instructions: {
            value: formData.notices ? formData.notices : '',

        },

    })


    const handleInput = (inputType, text) => {
        setForm({ ...form, [inputType]: { value: text, error: false, touched: true } })
    }

    const logged = useSelector(state => state.general.isLogged)

    const handleButton = () => {

        let val = 0

        for (const key in form) {
            if (form[key].error === true && form[key].value === '') {
                val++
                form[key].touched = true
            } else if ((form[key].value).trim() === '' && form[key].error === false) {
                form[key].error = true
                val++
            }
            setRefresh(!refresh)
        }
        let AdressData = { "id": id, "postcode": form.pcode.value, "name": form.person.value, "phone": form.phone.value, "city": form.city.value, "street": form.street.value, "street_no": form.homeNr.value, "street_no2": form.flatNr.value, "notices": form.instructions.value }

        if (val === 0 && title === 'Edytuj adres') {
            Axios.post('mzmapp/addressupdate', AdressData).then((response) => {
                const res = response.data


                if (res.err) {
                    console.log(res.err)
                    setShowM(true)
                    setModalText(res.err[Object.keys(res.err)])
                } else if (!res.err) {
                    setShowM(true)
                    setModalText('adres zaktualizowany')
                    dispatch(addressesList(response.data.address))
                }
            }).catch(err => {
                console.log(err)
            })
        } else if (val === 0 && title === 'Dodaj adres') {
            Axios.post('mzmapp/addressadd', { "reservation_id": "1", "postcode": form.pcode.value, "name": form.person.value, "phone": form.phone.value, "city": form.city.value, "street": form.street.value, "street_no": form.homeNr.value, "street_no2": form.flatNr.value, "notices": form.instructions.value }).then((response) => {
                const res = response.data

                if (res.err) {
                    console.log(res.err)
                    setShowM(true)
                    setModalText(res.err[Object.keys(res.err)])
                } else if (!res.err) {
                    setShowM(true)
                    setModalText('adres zaktualizowany')
                    dispatch(addressesList(response.data.address))
                }
            }).catch(err => {
                console.log(err)
            })
        } 
    }

    const showModal = () => {
        setShowM(!showM)
        if(props.fromForm){
            handleClose('edit')
        }

    }



    return (


        <EditWrapper>
            {showM && <Modal showModal={showModal} text={modalText} />}
            <header>
                <ion-icon name="chevron-back" style={{ marginLeft: 10, color: "#666" }}
                    onClick={() => { handleClose('edit') }}
                ></ion-icon><h3>{title ? title : ''}</h3>
            </header>
            <div style={{height:"100%", position: "relative", borderBottom: "1px solid #ecf0f1", margin: "10px 0", paddingBottom: 40, overflow:"auto" }}>
                <div style={{paddingBottom:"200px"}}>
                    <h4>Adres dostawy</h4>

                    <CustomInput form={form.person} type={"person"} name={logged ? "Adresat" : "Imię i nazwisko"} handleInput={handleInput} required />
                    <CustomInput form={form.phone} type={"phone"} name={"Telefon"} handleInput={handleInput} required />
                    <CustomInput form={form.pcode} type={"pcode"} name={"Kod pocztowy"} handleInput={handleInput} required />
                    <CustomInput form={form.city} type={"city"} name={"Miasto"} handleInput={handleInput} required />
                    <CustomInput form={form.street} type={"street"} name={"Ulica"} handleInput={handleInput} required />
                    <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                            <CustomInput form={form.homeNr} type={"homeNr"} name={"Nr budynku"} handleInput={handleInput} required />
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                            <CustomInput form={form.flatNr} type={"flatNr"} name={"Nr lokalu"} handleInput={handleInput} />
                        </div>
                    </div>
                    <CustomInput form={form.instructions} type={"instructions"} name={"Wskazówki dla kierowcy"} handleInput={handleInput} />


                    <div style={{ display: "flex", position: "relative", justifyContent: "center", alignItems: "center", paddingVertical: 15 }}>
                        <ButtonSmall

                            style={{ bottom: "-60px", color: "#fff", textAlign: "center" }}
                            onClick={() => { handleButton() }}>
                            <p>{title && title === 'Dodaj adres' ? 'Dodaj adres dostawy' : 'Zapisz dane'}</p>
                        </ButtonSmall>
                    </div>
                </div>


            </div>

        </EditWrapper>

    )
}

export default EditAddress