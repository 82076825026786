import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { Axios, storeData } from "../tools/axios";
import { isLogged } from "../redux/generalSlice";
import { H1, P, ButtonSmall, H2 } from "./style";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/images/logo.svg"
import Modal from "../modal/modal"

import CustomInput from "./components/custominput";

const Register = () => {

    const [form, setForm] = useState({
        name: {
            value: '',
            error: true,
            touched: false,
        },
        surname: {
            value: '',
            error: true,
            touched: false,
        },
        email: {
            value: '',
            error: true,
            touched: false,
        },
        phone: {
            value: '',
            error: true,
            touched: false,
        },
        password: {
            value: '',
            error: true,
            touched: false,
        },
        pcode: {
            value: '',
            error: true,
            touched: false,
        },
        rules: {
            checked: false,
            touched: false,
        },
        rights1: {
            checked: false,
            touched: false,
        },
    })

    const [all, setAll] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [info, setInfo] = useState(false)
    const [show, setShow] = useState(false)
    const [modalText, setModalText] = useState(false)
    const dispatch = useDispatch()
    let navigate = useNavigate();
    const topForm = useRef(null)


    useEffect(() => {

    }, [])

    const handleInput = (inputType, text) => {

        setForm({ ...form, [inputType]: { value: text, error: false, touched: true } })
    }

    const handlePassword = () => {
        setShowPassword(!showPassword)
    }

    const handleAllRights = () => {
        if (!all) {
            setForm({
                ...form,
                rules: { checked: true, touched: true },
                rights1: { checked: true, touched: true },
            })
            setAll(true)
        } else {
            setForm({
                ...form,
                rules: { checked: false, touched: true },
                rights1: { checked: false, touched: true },
            })
            setAll(false)
        }

    }

    const handlePost = () => {

        let val = 0


        for (const key in form) {

            if (form[key].error === true && form[key].value === '') {
                val++
                form[key].touched = true
                setModalText('Wszystkie pola z gwiazdką są wymagane')
                setShow(true)
                topForm.current.scrollIntoView()
            }     
            if (key === 'rules' && form[key].checked === false) {
                form[key].touched = true
            }  
        }
        

        const data = {
            name: form.name.value,
            surname: form.surname.value,
            email: form.email.value,
            phone: form.phone.value,
            password: form.password.value,
            postcode: form.pcode.value,
            rules: form.rules.checked,
        }

        if (val === 0 && (form.rules.checked === true)) {
            Axios.post('mzmapp/register', data).then((response) => {
                const newData = response.data
                if (newData.err) {
                    setModalText(newData.err[Object.keys(newData.err)])
                    setShow(true)
                } else {

                    dispatch(isLogged(true))
                    const token = newData.token
                    storeData(`${token}`)
                    navigate('/home')
                }

            }).catch(err => {
                console.log(err)
            });
        } else {

        }

    }

    const showModal = () => {
        setShow(!show)
    }

    return (
        <React.Fragment>
            {show && <Modal text={modalText} showModal={showModal} />}
            <div style={{ paddingBottom: 110 }}>
                <div style={{ alignItems: "center", justifyContent: "center", backgroundColor: "#00549D", padding: 10 }}>
                    <img ref={topForm} src={Logo} alt="logo" style={{ resizeMode: 'contain', width: 128, height: 44, margin: "0 auto", display: "block" }}
                    ></img></div>
                <div style={{ borderBottom: "1px solid #95a5a6", paddingBottom: 20, backgroundColor: "white" }}>
                    <H1 color={"#00549D"} style={{ margin: "0 20px", paddingTop: 10 }}>Utwórz konto</H1>
                    <P>Zarejestruj się w Anabru.pl i korzystaj z kuponów i zniek przygotowanych specjalnie dla Ciebie!</P>

                    <div style={{ position: "relative", borderBottomWidth: 2, borderBottomColor: "#ecf0f1", marginHorizontal: 5 }}>
                        <H2 style={{ fontSize: 14 }}>Dane do rejestracji</H2>


                        <CustomInput form={form.name} type={"name"} name={"Imię"} handleInput={handleInput} required={true} />
                        <CustomInput form={form.surname} type={"surname"} name={"Nazwisko"} handleInput={handleInput} required={true} />
                        <CustomInput form={form.email} type={"email"} name={"Adres email"} handleInput={handleInput} required={true} />
                        <CustomInput form={form.phone} type={"phone"} name={"Telefon"} handleInput={handleInput} required={true} />
                        <CustomInput form={form.password} type={"password"} name={"Hasło"} handleInput={handleInput} showPassword={showPassword} handlePassword={handlePassword} required={true} />
                        <CustomInput form={form.pcode} type={"pcode"} name={"Kod pocztowy"} handleInput={handleInput} required={true} />

                        <div>
                            <H2 style={{ fontSize: 14 }}>Twoja lokalizacja umożliwi nam dopasować się do Ciebie asortyment produktów oraz metodę dostawy</H2>
                        </div>
                        <div style={{ borderTop: "1px solid #95a5a6", background: "#fff" }}>
                            <H2 style={{ fontSize: "20px" }}>Zgody</H2>
                            <div onClick={() => handleAllRights()}
                                style={{ display: "flex", flexDirection: "row", paddingLeft: 20, alignItems: "baseline" }}>
                                {!all ?
                                    <ion-icon style={{ color: "#95a5a6" }} name="square-outline"></ion-icon> :
                                    <ion-icon style={{ color: "#00549D" }} name="square"></ion-icon>}

                                <P>Zaznacz wszystkie zgody</P>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", paddingLeft: 20, alignItems: "baseline" }}>
                                {!form.rules.checked ? <ion-icon name="square-outline" style={{ color: (form.rules.checked === false && form.rules.touched ===  true) ? "red" : "#95a5a6" }}
                                    onClick={() => { setForm({ ...form, rules: { checked: true, touched: true } }) }}
                                ></ion-icon> :
                                    <ion-icon name="square" style={{ color: "#00549D" }}
                                        onClick={() => { setForm({ ...form, rules: { checked: false, touched: true } }) }}
                                    ></ion-icon>}

                                <P>Akceptuję regulamin Anabru*</P>
                            </div>
                            <P>Aby otrzymywać od nas informacje o promocjach i rabatach, wyraź poniższe dobrowolne zgody. Masz prawo wycofać je w dowolnym momencie. Wycofanie zgody nie wpływa na zgodność z prawem działań podjętych na podstawie zgody przed jej wycofaniem.</P>
                            <div style={{ display: "flex", flexDirection: "row", marginBottom: 10, paddingRight: "20px" }}>
                                {!form.rights1.checked ? <ion-icon name="square-outline" style={{ color: "#95a5a6", width: "100%" }}
                                    onClick={() => { setForm({ ...form, rights1: { checked: true, touched: true } }) }}
                                ></ion-icon> :
                                    <ion-icon name="square" style={{ color: "#00549D", width: "100%" }}
                                        onClick={() => { setForm({ ...form, rights1: { checked: false, touched: true } }) }}
                                    ></ion-icon>}

                                <P style={{ margin: 0 }}>Wyrażam zgodę na otrzymywanie od Anabru z siedzibą w 05-506 Władysławów, Żwirowa 53/55, dostosowanych do moich preferencji informacji handlowych w postaci bezpłatnego newslettera (zawierającego w szczególności informacje o promocjach cenowych oraz kuponach rabatowych) pocztą elektroniczną.</P>
                            </div>

                        </div>


                        <div style={{ display: "flex", textAlign: "center", position: "relative", justifyContent: "center", alignItems: "center", padding: "15px 0", borderBottom: "1px solid #95a5a6" }}>
                            <ButtonSmall  color={"#fff"} onClick={() => { handlePost() }}>
                                <p>Utwórz konto</p>
                            </ButtonSmall>
                        </div>

                    </div>

                    <p style={{ paddingVertical: 20, paddingTop: 30, textAlign: "center", width: "100%" }}
                        onClick={() => { }}
                    >
                        Administratorem Twoich danych osobowych jest Anabru z siedzibą w 05-506 Władysławów, Żwirowa 53/55</p>
                </div>

                <div style={{ display: "flex", flexDirection: "column", position: "relative", justifyContent: "center", alignItems: "center", paddingBottom: 40, borderBottom: "1px solid #95a5a6", marginTop: 10, backgroundColor: "white" }}>
                    <H1 style={{ color: "#00549D" }}>Masz już konto?</H1>
                    <p style={{ textAlign: "center" }}>Zaloguj się, aby uzyskać dostęp do konta.</p>
                    <ButtonSmall bgColor={"#fff"} style={{ textAlign: "center", border: "1px solid #ecf0f1" }}
                        onClick={() => { navigate(`/`) }}>
                        <p>Zaloguj się</p>
                    </ButtonSmall>
                </div>
            </div>
        </React.Fragment>

    )
}

export default Register