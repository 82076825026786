import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { Axios, storeData } from "../tools/axios";
import { Prices, AddWrapper } from "./styles";
import { useNavigate, useLocation } from "react-router-dom";
import { addProduct, favsList, isShown, listProduct, isModal, isModalText, priceUpdate } from "../redux/generalSlice";

const ProductSmall = (props) => {

    const [clicked, setClicked] = useState(false)
    const [count, setCount] = useState(1)
    const [debounce, setDebounce] = useState(false)
    const [fav, setFav] = useState(false)
    const dispatch = useDispatch()
    const favProds = useSelector(state => state.general.favs)
    const isLogged = useSelector(state => state.general.isLogged)
    const [mounted, setMounted] = useState(false)
    const [errorText, setErrorText] = useState('')
    const [onlyLogged, setOnlyLogged] = useState(false)
    let navigate = useNavigate();
    let location = useLocation();

    const { product, error } = props




    useEffect(() => {
        if (product.count >= 1) {
            setCount(parseInt(product.count))
            setClicked(true)
        } else {
            setClicked(false)
        }
        if (favProds) {
            Object.keys(favProds).map(prod => {
                if (prod === product.id) setFav(true)
            })
            if (error) {
                error.map(element => {
                    if (element !== undefined) setErrorText(element)
                })
            }
        }
    }, [])


    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
          if (count >= 1 && debounce) {
            const data = { "product_id": product.id, "count": parseInt(count)}
            Axios.post('mzmapp/setcart', data).then((response) => {
            const res = response.data
            dispatch(priceUpdate(parseFloat(res.cart.vcosts_products)))   
      console.log(res)
        }).catch(err => {
            console.log(err)
        })
          }
        }, 600)
      
        return () => clearTimeout(delayDebounceFn)
      }, [count])

    useEffect(() => {
        if (error) {
            error.map(element => {
                if (element !== undefined) setErrorText(element)
            })
        }
    })

    const handleAddProduct = (product) => {
        setClicked(true)
        const data = { "product_id": product.id, "count": 1 }

        Axios.post('mzmapp/setcart', data).then((response) => {
            const newData = response.data
            if (newData.err) {
                dispatch(isModalText(newData.err.product_id))
                dispatch(isModal(true))
                setClicked(false)
            }
            const token = newData.token
            storeData(`${token}`)
            dispatch(addProduct(newData.cart.vcosts_products))
        }).catch(err => {
            console.log(err)
        })
    }


    const Add = (product) => {
        setCount(prevCount => prevCount + 1)
        setDebounce(true)
        // const data = { "product_id": product.id, "count": parseInt(count) + 1 }
        // const timer = setTimeout(() => {
        //     Axios.post('mzmapp/setcart', data).then((response) => {
        //         const newData = response.data
        //         const token = newData.token
        //         storeData(`${token}`)
        //         dispatch(addProduct(newData.cart.vcosts_products))
        //     }).catch(err => { console.log(err) })
        // }, 1000);
    }

    const Decrease = (product) => {
        setDebounce(true)
        setCount(prevCount => prevCount - 1)

        if (count === 1) {
            const data = { "product_id": product.id, "count": 0 }
            Axios.post('mzmapp/setcart', data).then((response) => {
                const newData = response.data
                const token = newData.token
                storeData(`${token}`)
                dispatch(addProduct(newData.cart.vcosts_products))
            }).catch(err => { console.log(err) })
            setClicked(false)

        } else if (count === 0 || count < 0) {
            setClicked(false)
            setCount(1)
        }
        // else {
        //     const data = { "product_id": product.id, "count": parseInt(count) - 1 }
        //     Axios.post('mzmapp/setcart', data).then((response) => {
        //         const newData = response.data
        //         const token = newData.token
        //         storeData(`${token}`)
        //         dispatch(addProduct(newData.cart.vcosts_products))
        //     }).catch(err => {console.log(err)})
        // }
    }

    const handleFav = (prodId) => {
        if (!fav && isLogged) {
            setFav(true)
            Axios.post('mzmapp/favadd', { "product_id": prodId }).then((response) => {
                dispatch(favsList(response.data.fav_products))
            }).catch(err => {
                console.log(err)
            })
        } else if (fav && isLogged) {
            setFav(false)
            Axios.post('mzmapp/favremove', { "product_id": prodId }).then((response) => {
                dispatch(favsList(response.data.fav_products))

            }).catch(err => {
                console.log(err)
            })
        } else {
            setOnlyLogged(true)
            setTimeout(() => {
                setOnlyLogged(false)
            }, 3000)
        }
    }
    const handleList = (p) => {
        if (isLogged) {
            dispatch(listProduct(p))
            dispatch(isShown(true))
        } else {
            setOnlyLogged(true)
            setTimeout(() => {
                setOnlyLogged(false)
            }, 3000)
        }
    }

    return (
        <React.Fragment>
            <div

                style={{ justifyContent: "space-between", display: "flex", flexDirection: "row", borderBottom: "1px solid #bdc3c7", padding: 10, position: 'relative' }}>
                <AddWrapper>
                    {!fav ?
                        <ion-icon name="star-outline"
                            onClick={() => { handleFav(product.id) }}
                        ></ion-icon> :
                        <ion-icon name="star" style={{ color: "#00549d" }}
                            onClick={() => { handleFav(product.id) }}
                        ></ion-icon>}


                    <ion-icon name="reader-outline"
                        onClick={() => { handleList(product) }}
                    ></ion-icon>
                    {onlyLogged && <span>opcja dostępna tylko dla zalogowanych</span>}
                </AddWrapper>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: 50 }} onClick={() => { navigate(`/product?mp=${product.id}`, { state: { id: product.id } }); }}>
                    <img src={product.image}
                        style={{ resizeMode: 'contain', maxWidth: "70px", maxHeight: "70px", width: "auto", height: "auto", justifyContent: "center", marginTop: 10 }} />
                </div>
                <div
                    onClick={() => { navigate(`/product?mp=${product.id}`, { state: { id: product.id } }); }}
                    style={{ display: "flex", flexDirection: "column", flex: 1, padding: "0 20px 45px", position: "relative" }}>
                    <p style={{ fontWeight: "bold", marginBottom: 5, color: "#666" }}>{product.brand} {product.name}</p>

                    {(Object.keys(product.attr).length > 0) &&
                        <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", marginVertical: 5 }}>
                            <p style={{ fontSize: 12, color: "#7f8c8d" }}>{product.attr[0].l}</p><p style={{ fontSize: 12, color: "#7f8c8d" }}>{product.attr[0].r}</p>
                        </div>
                    }
                    {error && errorText && <p style={{ margin: "0", width: "100%", color: "red" }}>{error}</p>}
                    {product.html_in ? <div style={{ margin: "5px 0" }} dangerouslySetInnerHTML={{ __html: product.html_in }} /> : <div style={{ height: "14px", padding: 10 }}></div>}
                    <Prices price_old={product.price_old}>
                        <span>{product.price}zł</span>{product.price_old && <p className="old">{product.price_old}zł</p>}
                    </Prices>
                    {/* <p style={{ fontWeight: "bold", color: "#00549D", position: "absolute", bottom: 0, left: 20 }}>{product.price} zł</p> */}
                </div>
                {!props.preview &&
                    <>
                        {!clicked ? <div
                            onClick={() => { handleAddProduct(product) }}
                            style={{ display: "flex", backgroundColor: "#00549D", borderRadius: 20, alignItems: "center", padding: 10 }}>
                            <svg xmlns="http://www.w3.org/2000/svg" style={{ width: "15px", height: "15px", fill: "#fff" }} viewBox="0 0 26.81 24.3" ><defs /><g id="Warstwa_2" data-name="Warstwa 2"><g id="pasek_gora"><path d="M26.4,9.15a2.29,2.29,0,0,0-1.64-1L16,.21a.78.78,0,0,0-1.11.05.79.79,0,0,0,0,1.12l7.46,6.77h-18l7.46-6.77A.79.79,0,1,0,10.79.21L2,8.17A2.3,2.3,0,0,0,.13,11.22L4.24,22.77A2.3,2.3,0,0,0,6.41,24.3H20a2.32,2.32,0,0,0,2.15-1.47l4.46-11.55A2.28,2.28,0,0,0,26.4,9.15Zm-1.22,1.56L20.71,22.26a.7.7,0,0,1-.67.46H6.41a.71.71,0,0,1-.68-.48L1.62,10.69A.72.72,0,0,1,1.71,10a.7.7,0,0,1,.59-.31H24.51a.71.71,0,0,1,.59.32A.68.68,0,0,1,25.18,10.71Z" /><rect x="12.31" y="12.21" width="2.19" height="8.03" rx="0.8" /><rect x="17.68" y="12.21" width="2.19" height="8.03" rx="0.8" /><rect x="6.92" y="12.21" width="2.19" height="8.03" rx="0.8" /></g></g></svg>
                        </div> :
                            <div
                                style={{ display: "flex", flexDirection: 'column', backgroundColor: "white", borderRadius: 20, justifyContent: "space-around", border: "1px solid #bdc3c7", padding: 5, }}>

                                <ion-icon style={{ color: "#00549D", fontSize:22 }} name="add-circle"
                                    onClick={() => { Add(product) }}
                                ></ion-icon>
                                <p style={{ alignSelf: "center" }}>{count}</p>
                                {count > 1 ? <ion-icon style={{ color: "grey", fontSize:22 }} name="remove-circle"
                                    onClick={() => { Decrease(product) }}></ion-icon> :
                                    <ion-icon style={{ color: "grey", fontSize:22 }} name="trash-bin"
                                        onClick={() => { Decrease(product) }}
                                    ></ion-icon>}
                            </div>}
                    </>}

            </div>
        </React.Fragment>
    )
}

export default ProductSmall