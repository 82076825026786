import React, { useEffect, useState } from "react";
import { Axios } from "../tools/axios";
import { useLocation } from "react-router-dom";
import Spinner from "../../assets/images/spinner.gif"



const NotFound = () => {

    let location = useLocation()

    const [content, setContent] = useState('')
    const [mounted, setMounted] = useState(false)

    useEffect(() => {
        let path = location.pathname

        Axios.get(`mzmapp/404?url=${path}`).then((response) => {
            const res = response.data
            setContent(res)
            setMounted(true)
        }).catch(err => { console.log(err) })
    })

    return (
        <div>
            {!mounted ?
                <div style={{ height: "100vh", background: "#fff", display: "flex", justifyContent: "center" }}>
                    <div style={{ maxWidth: 80, maxHeight: 60, marginTop: 120 }}>
                        <img src={Spinner} alt="loading" style={{ width: "100%", height: "100%" }}></img>
                    </div>
                </div> :
                <div dangerouslySetInnerHTML={{ __html: content }} style={{ padding: "10px", overflow: "auto" }}>
                </div>}
        </div>

    )
}

export default NotFound