import React, { useState, useEffect } from "react";
import { Container, SearchButton, SearchInput, H1 } from "../categories/styles";
import { BoxView, ListsWrapper, ListOption, Btn } from "./styles";
import { useNavigate } from "react-router-dom";
import { Axios } from "../tools/axios";
import { useSelector, useDispatch } from "react-redux";
import ListModal from "./listModal";
import Modal from "../modal/modal";
import { isModal, priceUpdate } from "../redux/generalSlice";


import ProductSmall from "../product/productSmall"


const Lists = () => {

    const [mounted, setMounted] = useState(false)
    const [fav, setFav] = useState({})
    const [lists, setLists] = useState({})
    const favProds = useSelector(state => state.general.favs)
    const isShown = useSelector(state => state.general.isShown)
    const isLogged = useSelector(state => state.general.isLogged)
    const isModalShow = useSelector(state => state.general.modal)
    const modalTxt = useSelector(state => state.general.modal_text)

    useEffect(() => {
        Axios.get('mzmapp/fav').then((response) => {
            setFav(response.data.fav_products)
        }).catch(err => {
            console.log(err)
        })
        Axios.get('mzmapp/favlist').then((response) => {
            setLists(response.data.fav_list)
        }).catch(err => {
            console.log(err)
        })
    }, [])



    const [active, setActive] = useState('listy')
    let navigate = useNavigate()
    let dispatch = useDispatch()

    const handleClose = () => {
        dispatch(isModal(false))
    }

    const AddListToCart = (list_id) => {
        Axios.post('mzmapp/favlistproductaddtocart',{"list_id":list_id}).then((response) => {
            const res = response.data
            dispatch(priceUpdate(res.cart.vcosts_products))
        
        }).catch(err => {
            console.log(err)
        })
    }

    return (
        <React.Fragment>
            {isShown && <ListModal />}
            {isModalShow && <Modal text={modalTxt} showModal={handleClose} />}
            <Container>
                <SearchButton>
                    <SearchInput onClick={() => navigate('/search')}>
                        <ion-icon style={{ marginRight: 20 }} name="search-outline"></ion-icon>
                        <p>Czego szukasz?</p>
                    </SearchInput>
                </SearchButton>
            </Container>
            <ListsWrapper>
                <div className="selection">
                    <BoxView active={active === 'listy'}
                        onClick={() => { setActive('listy') }}
                    ><ion-icon name="reader"></ion-icon><p>listy zakupowe</p></BoxView>
                    <BoxView active={active === 'ulubione'}
                        onClick={() => { setActive('ulubione') }}
                    ><ion-icon name="star"></ion-icon><p>moje ulubione</p></BoxView>
                </div>
            </ListsWrapper>
            {/* {isLogged &&  */}
            <div style={{ marginTop: 10 }}>
                {active === 'listy' ?
                    <ListOption>
                        {!isLogged || (lists && Object.keys(lists).length === 0)  ?
                            <p style={{ textAlign: "center" }}>{isLogged ? 'Brak list zakupowych' : 'opcja tylko dla zalogowanych'}</p> :
                            <>
                            {lists && Object.keys(lists).length > 0 &&
                            <>
                                {Object.keys(lists).map(item => {
                                    let prod = lists[item].rows
                                    return (
                                        <div className="singleList" key={lists[item].id}>
                                            <div className="imagesWrapper">
                                                {Object.keys(prod).map(element => {
                                                    return (
                                                        <img
                                                            src={prod[element].image}
                                                            key={prod[element].id} />
                                                    )
                                                })}

                                            </div>
                                            <div className="textWrapper">
                                                <div>
                                                    <h3>{lists[item].name}</h3>
                                                    {/* <p>Wartość listy:</p> */}
                                                </div> <span>{Object.keys(prod).length} {Object.keys(prod).length > 1 ? 'produkty' : 'produkt'}</span></div>
                                            <div className="buttonsWrapper">
                                                <Btn 
                                                onClick={()=>navigate(`/list?ml=${lists[item].id}`)}
                                                bg={"#fff"} text={"#00549D"} border>Wyświetl listę</Btn>
                                                <Btn
                                                onClick={()=>AddListToCart(lists[item].id)}
                                                >Dodaj do koszyka</Btn>
                                                </div>
                                        </div>

                                    )
                                })}
                                </>}
                            </>}
                    </ListOption>

                    :
                    <>
                    {!isLogged ? <div><p style={{ paddingTop:10,textAlign: "center" }}>opcja tylko dla zalogowanych</p></div> :

                    <div style={{ background: "#fff" }}>
                        {Object.keys(favProds).map(f => {
                            return (
                                <ProductSmall key={f} product={favProds[f]} />
                            )
                        })}
                    </div>}
                    </>
                    

                }
            </div>



        </React.Fragment>
    )
}

export default Lists