import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Axios, storeData } from "../tools/axios";
import { isLogged } from "../redux/generalSlice";
import { H1, P, InputLabel, InputWrapper, ButtonSmall } from "./style";
import { useNavigate } from "react-router-dom";
import Modal from "../modal/modal";
import Logo from "../../assets/images/logo.svg"
import Dashboard from "./dashboard";

const Login = () => {

    const [email, setEmail] = useState({
        value: '',
        error: false,
        allow: false,
    });
    const [password, setPassword] = useState({
        value: '',
        error: false,
        allow: false,
    })

   

    const [showPassword, setShowPassword] = useState(false)
    const [info, setInfo] = useState(false)
    const dispatch = useDispatch()
    const logged = useSelector(state => state.general.isLogged)
    const [show, setShow] = useState(false)
    const [modalText, setModalText] = useState('')

    let navigate = useNavigate();


    const handleEmail = (text) => {
        setEmail({ value: text, error: false, allow: true })
    }

    const handlePassword = (text) => {
        setPassword({ value: text, error: false, allow: true })
    }

    const handleNotLogged = () => {
        navigate(`/home`)
    }

    const handleLogin = () => {
        setInfo(true)
        if (email.value.length === 0) {
            setEmail({ ...email, error: true })
        }

        if (password.value.length === 0) {
            setPassword({ ...password, error: true })
        }

        const data = {
            email: email.value,
            password: password.value
        }

        

        if (!email.error && !password.error) {
            Axios.post('mzmapp/login', data).then((response) => {
                const newData = response.data
                if (response.data.ok === 1 && !newData.err) {
                    dispatch(isLogged(true))
                    navigate(`/home`)
                } if (response.data.err){
                    setModalText(response.data.err.password)
                    showModal(true)
                }
                const token = newData.token
                storeData(`${token}`)
            }).catch(err => {
                console.log(err)
            });
        }
    }

    const showModal = () => {
        setShow(!show)
    }


    return (
        <React.Fragment>
            {!logged ?
                <React.Fragment>
                    {show && <Modal text={modalText} showModal={showModal} />}
                    <div>
                        <div style={{ alignItems: "center", justifyContent: "center", backgroundColor: "#00549D", padding: 10 }}>
                            <img src={Logo} style={{ resizeMode: 'contain', width: 128, height: 44, margin: "0 auto", display: "block" }}
                            ></img></div>
                        <div style={{ borderBottom: "1px solid #95a5a6", marginBottom: 10,paddingTop:10, backgroundColor: "white" }}>
                            <H1 style={{margin:"0px", paddingLeft:20}} color={"#00549D"}>Witaj!</H1>
                            <p style={{margin:"2px 20px"}}>Zaloguj się, aby dostać dostęp do konta.</p>
                            {(email.value.length > 0) ? <InputLabel>Adres email</InputLabel> : <div style={{ margin: "0 0 0 10px", height: "10px" }}></div>}
                            <div style={{ position: "relative", borderBottomWidth: 2, borderBottomColor: "#ecf0f1", marginHorizontal: 5 }}>
                                <InputWrapper error={email.error || (info && (email.value.length === 0))} style={{ display: "flex", flexDirection: "row" }}>

                                    <input
                                        placeholder="Adres email"
                                        style={{ paddingLeft: 10, width: "100%" }}
                                        onChange={(e) => { handleEmail(e.target.value) }}
                                        value={email.value}
                                    >
                                    </input>
                                    {info && (email.value.length > 0) && <p>a</p>}
                                    {info && (email.value.length === 0) && <p>b</p>}

                                </InputWrapper>
                                {info && (email.value.length === 0) && <p style={{ marginLeft: 10, color: "red" }}>Pole jest wymagane</p>}
                                {(password.value.length > 0) ? <InputLabel>Hasło</InputLabel> : <div style={{ margin: "0 0 0 10px", height: "10px" }}></div>}
                                <InputWrapper error={password.error || (info && (password.value.length === 0))}>

                                    <input
                                        placeholder="Hasło"
                                        style={{ paddingLeft: 10, width: "100%" }}
                                        onChange={(e) => { handlePassword(e.target.value) }}
                                        value={password.value}
                                        type={!showPassword ? "password" : "text"}
                                    ></input>

                                    {!showPassword ?
                                        <ion-icon name="eye" onClick={() => { setShowPassword(!showPassword) }}></ion-icon> :
                                        <ion-icon name="eye-off" onClick={() => { setShowPassword(!showPassword) }}></ion-icon>}
                                    {info && (password.value.length > 0) && <ion-icon name="eye"></ion-icon>}
                                    {info && (password.value.length === 0) && <ion-icon name="eye"></ion-icon>}
                                </InputWrapper>
                                {info && (password.value.length === 0) && <p style={{ marginLeft: 10, color: "red" }}>Pole jest wymagane</p>}
                                <div style={{ display: "flex", textAlign: "center", position: "relative", justifyContent: "center", alignItems: "center", padding: "15px 0" }}>
                                    <ButtonSmall color={"#fff"} style={{border:"1px solid #f2f2f2"}} onClick={() => handleLogin()}>
                                        <p>Zaloguj się</p>
                                    </ButtonSmall>
                                </div>
                            </div>
                            <p style={{ padding: "30px 0px 10px", paddingTop: "30px ", textAlign: "center", width: "100%", color:"#00549D" }}
                                onClick={() => { navigate(`/remind`) }}
                            >
                                Nie pamiętasz hasła?</p>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", position: "relative", justifyContent: "center", alignItems: "center", paddingBottom: 40, borderBottom: "1px solid #95a5a6", marginTop: 10, backgroundColor: "white" }}>
                            <H1 style={{ color: "#00549D" }}>Nie masz jeszcze konta?</H1>
                            <p style={{ textAlign: "center" }}>Zarejestruj się we Anabru i korzystaj z kuponów i zniżek przygotowanych specjalnie dla Ciebie!</p>
                            <ButtonSmall bgColor={"#fff"} style={{ textAlign: "center", border: "1px solid #f2f2f2" }}
                                onClick={() => { navigate(`/register`) }}>
                                <p>Utwórz konto</p>
                            </ButtonSmall>
                        </div>
                        <p style={{ marginTop: 26, textAlign: "center", color: "#00549D" }}
                            onClick={() => { handleNotLogged() }}
                        >Przejdź do aplikacji bez logowania</p>
                    </div>
                </React.Fragment>
                : <Dashboard />}
        </React.Fragment>

    )
}

export default Login