import { createSlice } from '@reduxjs/toolkit'

export const generalSlice = createSlice({
    name: 'counter',
    initialState: {
        cart: [],
        totalPrice: 0,
        isLogged: false,
        favs: {},
        isShown: false,
        listProduct: {},
        addreses: {},
        activeAddress:'',
        modal_text: '',
        modal: false,
    },
    reducers: {
        addProduct: (state, action) => {
            state.totalPrice = action.payload
        },
        priceUpdate: (state, action) => {
            state.totalPrice = action.payload
        },
        isLogged: (state, action) => {
            state.isLogged = action.payload
        },
        favsList: (state, action) => {
            state.favs = action.payload
        },
        isShown: (state, action) => {
            state.isShown = action.payload
        },
        listProduct: (state, action) => {
            state.listProduct = action.payload
        },
        addressesList: (state, action) => {
            state.addreses = action.payload
        },
        setActiveAddress: (state, action) => {
            state.activeAddress = action.payload
        },
        isModal: (state, action) => {
            state.modal = action.payload
        },
        isModalText: (state, action) => {
            state.modal_text = action.payload
        },
    }
})

// Action creators are generated for each case reducer function
export const { addProduct, favsList, priceUpdate, isLogged, isShown, listProduct, addressesList, setActiveAddress, isModal, isModalText} = generalSlice.actions

export default generalSlice.reducer