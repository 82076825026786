
import styled from 'styled-components';

export const Container = styled.div`
display:flex;
backgroundColor: #b1b1b1;
margin: ${props => props.margin ? props.margin : 0}px;
flex-direction: ${props => props.row ? "row" : "column"};
align-content: space-around;
justify-content: space-evenly;
border-color:#ecf0f1;
bordertop-width: 1px;
border-bottom-width: 1px;
border-bottom-color: #bdc3c7;

margin-bottom:50px;
`
export const SearchButton = styled.a`
display:flex;
flex-direction:row;
align-items:center;
backgroundColor: white;
border-radius: 15px;
border: 1px solid #ecf0f1;
padding:5px;
position:fixed;
top:10px;
left:10px;
right:10px;
background:white;
`
export const SearchInput = styled.div`
display:flex;
flex-direction:row;
align-items:center;
backgroundColor: white;
border-color: #ecf0f1;
border-width: 1px;
border-radius: 15px;
margin-left:15px;
`

export const H1 = styled.h1`
color: ${props => props.color ? props.color : "#95a5a6"};
font-weight: bold;
margin:${props => props.marginH ? props.marginH : 10}px;
font-size:${props => props.size ? props.size : 20}px;
padding-left:5px;
`