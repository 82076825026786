import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./components/home/home";
import Categories from "./components/categories/categories";
import SubCategory from "./components/categories/subcategory";
import ProductsList from "./components/categories/producsList";
import Cart from "./components/cart/cart";
import Login from "./components/user/login";
import Register from "./components/user/register";
import Remind from "./components/user/remind";
import Form from "./components/cart/form";
import Dashboard from "./components/user/dashboard";
import ProductBig from "./components/product/productBig";
import Search from "./components/home/search";
import BottomBar from "./components/bottomBar/bottomBar";
import AddressList from "./components/user/addressList";
import OrdersList from "./components/user/orders";
import Order from "./components/user/order";
import Lists from "./components/lists/lists";
import List from "./components/lists/list";
import NotFound from "./components/home/notfound";
import OrdersHistory from "./components/user/ordersHistory";



function App() {

  return (
    <div style={{ position: "relative", height: "100vh", background: "#f4f4f4" }}>
      <Routes>

        <Route index exact path="/" element={<Login />} />
        <Route exact path="/register" element={<Register />} />
        <Route exact path="/remind" element={<Remind />} />
        <Route exact path="/home" element={<Home />} />
        <Route exact path="/dashboard" element={<Dashboard />} />
        <Route path="/profile/address" element={<AddressList />} />
        <Route path="/profile/orders" element={<OrdersList />} />
        <Route path="/profile/orders/order" element={<Order />} />
        <Route path="/profile/orders-history" element={<OrdersHistory />} />
        <Route exact path="/search" element={<Search />} />
        <Route exact path="/categories" element={<Categories />} />
        <Route exact path="/category/subcategory" element={<SubCategory />} />
        <Route exact path="/category/subcategory/products" element={<ProductsList />} />
        <Route exact path="/product" element={<ProductBig />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/order" element={<Form />} />
        <Route path="/lists" element={<Lists />} />
        <Route path="/list" element={<List />} />
        <Route path="*" element={<NotFound />} />

      </Routes>
      <BottomBar />
    </div>
  );
}

export default App;
