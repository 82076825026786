import React, { useState, useEffect } from 'react'
import InfoCard from './infoCard'
import { H1, H2, BtnBig, BtnAddBig, SearchButton, SearchInput, PriceBig, PriceBigWrapper, AddWrapper } from './styles'
import { useDispatch, useSelector } from 'react-redux'
import { Axios, storeData } from '../tools/axios'
import { useNavigate, useLocation } from 'react-router-dom'
import { addProduct, favsList, listProduct, isShown, isModal, isModalText } from '../redux/generalSlice'
import Modal from '../modal/modal'
import ProductLong from './productLong'
import Spinner from '../../assets/images/spinner.gif'


const ProductBig = (props) => {

    const [clicked, setClicked] = useState(false)
    const [debounce, setDebounce] = useState(false)
    const [count, setCount] = useState(1)
    const [mounted, setMounted] = useState(false)
    const dispatch = useDispatch()
    let navigate = useNavigate();
    let location = useLocation();
    const [fav, setFav] = useState(false)
    const [product, setProduct] = useState({})
    const [onlyLogged, setOnlyLogged] = useState(false)
    const favProds = useSelector(state => state.general.favs)
    const isLogged = useSelector(state => state.general.isLogged)
    const isModalShow = useSelector(state => state.general.modal)
    const modalTxt = useSelector(state => state.general.modal_text)




    useEffect(() => {

        let id = location.search.split("=")[1]

        Axios.get(`mzmapp/product?id=${id}`).then((response) => {
            setProduct(response.data.row)
            const token = response.data.token
            storeData(`${token}`)
            setMounted(true)
        }).catch(err => {console.log(err)})

        if (product.count >= 1) {
            setCount(parseInt(product.count))
            setClicked(true)
        } else {setClicked(false)}
    }, [])

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
          if (count >= 1 && debounce) {
            const data = { "product_id": product.id, "count": parseInt(count)}
            Axios.post('mzmapp/setcart', data).then((response) => {
            const res = response.data.rows
        }).catch(err => {
            console.log(err)
        })
          }
        }, 600)
      
        return () => clearTimeout(delayDebounceFn)
      }, [count])



    const handleAddProduct = (product) => {
        setClicked(true)
        const data = { "product_id": product.id, "count": "1" }
        Axios.post('mzmapp/setcart', data).then((response) => {
            const newData = response.data
            if (newData.err) {
                dispatch(isModalText(newData.err.product_id))
                dispatch(isModal(true))
                setClicked(false)
            }
            const token = newData.token
            storeData(`${token}`)
            dispatch(addProduct(newData.cart.vcosts_products))
        }).catch(err => {
            console.log(err)
        })
    }


    const Add = (product) => {
        setCount(prevCount => prevCount + 1)
        setDebounce(true)
        // const data = { "product_id": product.id, "count": parseInt(count) + 1 }
        // const timer = setTimeout(() => {
        //     Axios.post('mzmapp/setcart', data).then((response) => {
        //         const newData = response.data
        //         const token = newData.token
        //         storeData(`${token}`)
        //         dispatch(addProduct(newData.cart.vcosts_products))
        //     }).catch(err => {console.log(err)})
        // }, 1000);
    }

    const Decrease = (product) => {
        setDebounce(true)
        if (count === 1) {
            const data = { "product_id": product.id, "count": 0 }
            Axios.post('mzmapp/setcart', data).then((response) => {
                const newData = response.data
                const token = newData.token
                storeData(`${token}`)
                dispatch(addProduct(newData.cart.vcosts_products))
            }).catch(err => {console.log(err)})
            setClicked(false)

        } else if (count === 0 || count < 0) {
            setClicked(false)
            setCount(1)
        }
        else {
            setCount(prevCount => prevCount - 1)
            // const data = { "product_id": product.id, "count": parseInt(count) - 1 }
            // const timer = setTimeout(() => {
            //     Axios.post('mzmapp/setcart', data).then((response) => {
            //         const newData = response.data
            //         const token = newData.token
            //         storeData(`${token}`)
            //         dispatch(addProduct(newData.cart.vcosts_products))
            //     }).catch(err => {console.log(err)})
            // }, 1000);
        }
    }

    const handleFav = (prodId) => {
        if (!fav && isLogged) {
            setFav(true)
            Axios.post('mzmapp/favadd', { "product_id": prodId }).then((response) => {
                dispatch(favsList(response.data.fav_products))
            }).catch(err => {
                console.log(err)
            })
        } else if (fav && isLogged) {
            setFav(false)
            Axios.post('mzmapp/favremove', { "product_id": prodId }).then((response) => {
                dispatch(favsList(response.data.fav_products))

            }).catch(err => {
                console.log(err)
            })
        } else {
            setOnlyLogged(true)
            setTimeout(() => {
                setOnlyLogged(false)
            }, 3000)
        }
    }

    const handleList = (p) => {

        if (isLogged) {
            dispatch(listProduct(p))
            dispatch(isShown(true))
        } else {
            setOnlyLogged(true)
            setTimeout(() => {
                setOnlyLogged(false)
            }, 3000)
        }
    }

    const handleClose = () => {
        dispatch(isModal(false))
    }

    let price = `${product.price}`.split('.')

    return (
        <React.Fragment>
            {isModalShow && <Modal showModal={handleClose} text={modalTxt} />}
            <div style={{ position: "fixed", top: 0, left: 0, right: 0, background: "#fff", zIndex: 9999 }}>
                <SearchButton onClick={() => navigate('/search')}>
                    <SearchInput>
                        <ion-icon style={{ marginRight: 20 }} name="search-outline"></ion-icon>
                        <p style={{ color: "#666" }}>Czego szukasz?</p>
                    </SearchInput>
                </SearchButton>
                <div
                    onClick={() => navigate(-1)}
                    style={{ paddingTop: 55, paddingLeft: 10, background: "#fff", display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <ion-icon name="chevron-back" style={{ marginRight: 10, color: "#666" }}
                        onClick={() => navigate(-1)}
                    ></ion-icon>
                    <p style={{ color: "#666" }}>Wstecz</p></div>
            </div>
            <div style={{ padding: "70px 0 60px" }}>

                <div style={{ position: "relative" }}>
                    <AddWrapper>
                        {!fav ?
                            <ion-icon name="star-outline"
                                onClick={() => { handleFav(product.id) }}
                            ></ion-icon> :
                            <ion-icon name="star" style={{ color: "#00549d" }}
                                onClick={() => { handleFav(product.id) }}
                            ></ion-icon>}


                        <ion-icon name="reader-outline"
                            onClick={() => { handleList(product) }}
                        ></ion-icon>
                        {onlyLogged && <span>opcja dostępna tylko dla zalogowanych</span>}
                    </AddWrapper>
                    {mounted &&
                        <React.Fragment>
                            <div style={{ backgroundColor: "#fff", padding: "20px 10px 30px", position: "relative" }}>

                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <img src={product.image} alt={product.id}
                                        style={{ resizeMode: 'contain', maxWidth: 150, maxHeight: 150, width: "auto", height: "auto" }} />
                                </div>
                                <H2 color={"#00549D"} style={{ fontSize: 20, margin: "10px 0 10px 10px" }}>{product.name}</H2>
                                {(Object.keys(product.attr).length > 0) &&
                                    <p style={{ fontSize: 12, color: "#7f8c8d", marginLeft: "10px" }}>{product.attr[0].l}</p>}
                                {/* <View><Text>Super cena</Text></View> */}
                                <div style={{ display: "flex", alignItems: "center", flexDirection: "row", justifyContent: "center" }}>
                                    {price &&
                                        <React.Fragment>
                                            <PriceBig price_old={product.price_old}>{price[0]}</PriceBig>
                                            <PriceBigWrapper price_old={product.price_old}>
                                                <span>{price[1]}</span>
                                                <p>zł</p>
                                            </PriceBigWrapper>
                                        </React.Fragment>
                                    }
                                    {(Object.keys(product.attr).length > 0) &&
                                        <div>
                                            {product.price_old && <p style={{ textDecoration: "line-through" }}>{product.price_old}</p>}
                                            <p style={{ fontSize: 12, color: "#7f8c8d" }}>{product.attr[0].l}</p>
                                            <p style={{ fontSize: 12, color: "#7f8c8d" }}>{product.attr[0].r}</p>
                                        </div>}
                                </div>
                                {product.valid_date_to && <p style={{ color: "#666", fontSize: "13px", textAlign: "center", marginTop: 10 }}>Data ważności produktu: {product.valid_date_to}</p>}
                                <p style={{ color: "#666", fontSize: "13px", textAlign: "center", marginTop: 10 }}>Sprzedawcą poniższych produktów jest Anabru sp. z o.o.</p>

                                <div style={{ display: "flex", alignItems: "center", width: "200px", margin: "0 auto", borderBottomWidth: 1, borderBottomColor: "#bdc3c7", paddingBottom: 40 }}>

                                    {!clicked ? <BtnBig style={{ left: "auto" }} onClick={() => { handleAddProduct(product) }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" style={{ width: "25px", height: "25px", fill: "#fff" }} viewBox="0 0 26.81 24.3" ><defs /><g id="Warstwa_2" data-name="Warstwa 2"><g id="pasek_gora"><path d="M26.4,9.15a2.29,2.29,0,0,0-1.64-1L16,.21a.78.78,0,0,0-1.11.05.79.79,0,0,0,0,1.12l7.46,6.77h-18l7.46-6.77A.79.79,0,1,0,10.79.21L2,8.17A2.3,2.3,0,0,0,.13,11.22L4.24,22.77A2.3,2.3,0,0,0,6.41,24.3H20a2.32,2.32,0,0,0,2.15-1.47l4.46-11.55A2.28,2.28,0,0,0,26.4,9.15Zm-1.22,1.56L20.71,22.26a.7.7,0,0,1-.67.46H6.41a.71.71,0,0,1-.68-.48L1.62,10.69A.72.72,0,0,1,1.71,10a.7.7,0,0,1,.59-.31H24.51a.71.71,0,0,1,.59.32A.68.68,0,0,1,25.18,10.71Z" /><rect x="12.31" y="12.21" width="2.19" height="8.03" rx="0.8" /><rect x="17.68" y="12.21" width="2.19" height="8.03" rx="0.8" /><rect x="6.92" y="12.21" width="2.19" height="8.03" rx="0.8" /></g></g></svg></BtnBig>

                                        :
                                        <React.Fragment>
                                            <BtnAddBig
                                            >
                                                {count > 1 ? <ion-icon style={{ color: "#666", fontSize: "30px" }} name="remove-circle"
                                                    onClick={() => { Decrease(product) }}></ion-icon> :
                                                    <ion-icon name="trash-bin-sharp" style={{ fontSize: 30, color: "#666" }}
                                                        onClick={() => Decrease(product)}
                                                    ></ion-icon>}

                                                <p style={{ alignSelf: "center" }}>{count}</p>
                                                <ion-icon name="add-circle" style={{ fontSize: 30, color: "#00549D" }}
                                                    onClick={() => Add(product)}
                                                ></ion-icon>

                                            </BtnAddBig>
                                        </React.Fragment>}
                                </div>

                            </div>
                            <div>
                                <div style={{ backgroundColor: "#fff", padding: 5, borderBottom: "1px solid #bdc3c7", }}>
                                </div>
                                <div>
                                    {Object.keys(product.tabs).map(tab => {

                                        return (
                                            <InfoCard key={product.tabs[tab].name} info={product.tabs[tab]} />
                                        )
                                    })}

                                </div>

                            </div>
                            {/* <div style={{ borderBottom: "1px solid #bdc3c7" }}>
                            <H2 margin={"0"} padding={"10px"} background={"var(--background)"}>Klienci oglądali również</H2>
                            {!mounted ? <div style={{ backgroundColor: "#fff", alignItems: "center", display: "flex", justifyContent: "center" }}>
                                <img src={Spinner} style={{ width: "100%", height: "100%", maxWidth: 80, maxHeight: 80 }}></img>
                            </div> 
                            :
                                <div style={{ background: "#fff", display: "flex", flexDirection: "row", width: "100%", overflow: "auto" }}>
                                    {Object.keys(best).map(v => {
                                        return (
                                            <ProductLong key={best[v].id} product={best[v]} />)
                                    })}
                                </div>}
                        </div> */}


                        </React.Fragment>}
                </div>
            </div>
        </React.Fragment>)

}

export default ProductBig