import styled from 'styled-components';

export const Wrapper = styled.div`
display:flex;
flex-direction:row;
width: 100%;
backgroundColor: "#fff"; 
height: 70px; 
position: fixed; 
bottom: 0; 
left: 0;
justify-content:space-evenly;
border-top: 1px solid #ebebeb;
background:#fff;
z-index:9999;
span {
    background:#00549D;
    color:#fff;
    border-radius:5px;
    padding:2px 4px;
    font-size:10px;
}
`

export const Icon = styled.div`
    display:flex;
    margin:0 12px;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    color:#00549D;
    text-align:center;
    svg {
        fill: ${props => props.active ? "#fff" : "grey"};
        font-size:25px;
        width:100%;
        height:100%;
        max-width:25px;
        max-height:25px;
        background: ${props => props.active ? '#00549D' : 'transparent'};
        padding:10px;
        border-radius:25px;
        position:absolute;
        border:${props => props.border ? "2px solid #fff;" : "none"};
        top:${props => props.active ? "-20" : "-5"}px;
    }
    ion-icon {
        color: ${props => props.active ? "#fff" : "grey"};
        font-size:25px;
        background: ${props => props.active ? '#00549D' : 'transparent'};
        padding:10px;
        border-radius:25px;
        position:absolute;
        border:${props => props.border ? "2px solid #fff;" : "none"};
        top:${props => props.active ? "-20" : "-5"}px;
      }
    p{
        position:absolute;
        bottom:10px;
        color: ${props => props.active ? "#00549D" : "grey"};
        font-size:12px;
    }
    
}
`