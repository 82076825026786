import React, { useEffect, useState } from "react";

import ProductSmall from "../product/productSmall";
import { useDispatch, useSelector } from 'react-redux'
import { Axios, storeData } from "../tools/axios";
import { H1, SearchInput, SearchButton, OrderButton } from "./style";
import { useNavigate } from "react-router-dom";
import { priceUpdate } from "../redux/generalSlice";
import Modal from "../modal/modal";
import Spinner from "../../assets/images/spinner.gif"
import ListModal from "../lists/listModal";
import { isModalText } from "../redux/generalSlice";


const Cart = () => {
    const fullPrice = useSelector(state => state.general.totalPrice)
    const isShown = useSelector(state => state.general.isShown)
    const modalTxt = useSelector(state => state.general.modal_text)
    const [open, setOpen] = useState(false)
    const [productsCart, setProductsCart] = useState({})
    const [mounted, setMounted] = useState(false)
    const [nextDate, setNextDate] = useState('')
    const [show, setShow] = useState(false)
    const [errors, setErrors] = useState({})
    const [canChange, setCanChange] = useState(true)

    let navigate = useNavigate();
    const dispatch = useDispatch()

    useEffect(() => {
        Axios.get('mzmapp/user').then((response) => {
            const newData = response.data
            setCanChange(newData.cart.can_change)
            setErrors(newData.cart.rows_errors)  
            console.log(newData)
            if(newData.cart.can_change === false && newData.cart.error !== null){
                dispatch(isModalText(newData.cart.error))
                setShow(true) 
            } 
            const token = newData.token
            if (newData.reservation.date) {
                setNextDate(newData.reservation.date)
            } else {setNextDate(newData.reservation.next_date)}
            
            dispatch(priceUpdate(parseFloat(newData.cart.vcosts_products)))           
            storeData(`${token}`)    
            setProductsCart(newData.cart.rows)
            setMounted(true)
        }).catch(err => {
            console.log(err)
        })

    }, [fullPrice])

    const handleOrder = () => {
        if (parseFloat(fullPrice) < 100 || canChange === false) {
            setShow(true)
            dispatch(isModalText(`Do minimalnej wartości koszyka brakuje Ci jeszcze ${(100 - fullPrice).toFixed(2)}zł.`))
        } else if (canChange === true && Object.keys(errors).length === 0){
            navigate(`/order`)
        }
    }

    const showModal = () => {
        setShow(!show)
    }

    

    return (
        <React.Fragment>
            {isShown && <ListModal />}
            {show && <Modal text={modalTxt} showModal={showModal} />}
            <div style={{ height: "100%" }}>
                <div style={{ flex: 1 }}>
                    <SearchButton onClick={() => navigate('/search')}>
                        <SearchInput>
                            <ion-icon style={{ marginRight: 20 }} name="search-outline"></ion-icon>
                            <p>Czego szukasz?</p>
                        </SearchInput>
                    </SearchButton>
                    {!mounted ?
                            <div style={{ background: "#fff", display: "flex", justifyContent: "center",marginTop:60 }}>
                                <img src={Spinner} style={{width:"100%",height:"100%", maxWidth: 80, maxHeight: 80 }}></img>
                            </div> :
                    <React.Fragment>
                    {(Object.keys(productsCart).length > 0) && mounted ?
                        <React.Fragment>
                            <div style={{ marginTop: 60 }}>
                                {fullPrice < 100 &&
                                    <div style={{ fontSize:"18px",padding: "20px 10px", backgroundColor: "#fff", borderBottom: "1px solid #bdc3c7" }}>
                                        <p>Do minimalnej wartości koszyka brakuje Ci jeszcze <span style={{color:"var(--red)"}}>{(100 - fullPrice).toFixed(2)} zł.</span></p>
                                    </div>
                                }

                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", padding: "10px 10px" }}>
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        <H1 style={{ margin: 0 }}>Koszyk</H1><p style={{ marginLeft: 10 }}> {Object.keys(productsCart).length} {(Object.keys(productsCart).length) > 1 ? 'produktów' : 'produkt'} </p>
                                    </div>

                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        <H1>{fullPrice} zł</H1>
                                        {!open ? <ion-icon style={{ color: "grey" }} name="chevron-up"
                                            onClick={() => setOpen(true)}
                                        ></ion-icon> :
                                            <ion-icon style={{ color: "grey" }} name="chevron-down"
                                                onClick={() => setOpen(false)}
                                            ></ion-icon>}
                                    </div>

                                </div>
                      
                                {open && <div style={{ padding: "0 10px 10px 10px" }}>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", paddingHorizontal: 20 }}>
                                        <p style={{ margin: 5 }}>Sprzedawca</p><p style={{ margin: 5 }}>Anabru Sp. z o.o.</p>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", paddingHorizontal: 20 }}>
                                        <p style={{ margin: 5 }}>Koszyk ({Object.keys(productsCart).length} {(Object.keys(productsCart).length) > 1 ? 'produktów' : 'produkt'} )</p><p style={{ margin: 5 }}>{fullPrice} zł</p>
                                    </div>
                                </div>}
                                <div style={{ backgroundColor: "#fff", paddingBottom:200 }}>
                                    {Object.keys(productsCart).map(product => {
                                      let warning = Object.keys(errors).map(item => {if (item === productsCart[product].id){return errors[item]}})
                                      
                                        return (
                                            <ProductSmall key={productsCart[product].id} product={productsCart[product]} error={warning} />
                                        )
                                    })}
                                </div>

                            </div>
                        </React.Fragment>
                        :
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", paddingTop: 60 }}>
                            <svg xmlns="http://www.w3.org/2000/svg" style={{width:"100px", height:"100px", fill:"#666"}} viewBox="0 0 26.81 24.3" ><defs/><g id="Warstwa_2" data-name="Warstwa 2"><g id="pasek_gora"><path d="M26.4,9.15a2.29,2.29,0,0,0-1.64-1L16,.21a.78.78,0,0,0-1.11.05.79.79,0,0,0,0,1.12l7.46,6.77h-18l7.46-6.77A.79.79,0,1,0,10.79.21L2,8.17A2.3,2.3,0,0,0,.13,11.22L4.24,22.77A2.3,2.3,0,0,0,6.41,24.3H20a2.32,2.32,0,0,0,2.15-1.47l4.46-11.55A2.28,2.28,0,0,0,26.4,9.15Zm-1.22,1.56L20.71,22.26a.7.7,0,0,1-.67.46H6.41a.71.71,0,0,1-.68-.48L1.62,10.69A.72.72,0,0,1,1.71,10a.7.7,0,0,1,.59-.31H24.51a.71.71,0,0,1,.59.32A.68.68,0,0,1,25.18,10.71Z"/><rect  x="12.31" y="12.21" width="2.19" height="8.03" rx="0.8"/><rect  x="17.68" y="12.21" width="2.19" height="8.03" rx="0.8"/><rect x="6.92" y="12.21" width="2.19" height="8.03" rx="0.8"/></g></g></svg>
                            <p>W koszyku na razie pusto...</p>
                        </div>}
                        </React.Fragment>}
                </div>

                <div style={{ display: "flex", position: "fixed", bottom: 70, backgroundColor: "#00549D", height: 70, alignItems: "center", flexDirection: "row", justifyContent: "space-between", paddingHorizontal: 20, width: "100%", zIndex:"9999" }}>
                    <div>
                        <p style={{ color: "#fff", paddingLeft: 10 }}>Najbliższy termin</p>
                        <p style={{ color: "#fff", paddingLeft: 10 }}>{nextDate}</p>
                    </div>
                    <OrderButton error={Object.keys(errors).length > 0} onClick={() => { handleOrder() }} minDelivery={fullPrice > 100}>
                        <p>
                            <svg viewBox="0 0 24 24">
                                <path fill="#00549D" d="M18,18.5A1.5,1.5 0 0,1 16.5,17A1.5,1.5 0 0,1 18,15.5A1.5,1.5 0 0,1 19.5,17A1.5,1.5 0 0,1 18,18.5M19.5,9.5L21.46,12H17V9.5M6,18.5A1.5,1.5 0 0,1 4.5,17A1.5,1.5 0 0,1 6,15.5A1.5,1.5 0 0,1 7.5,17A1.5,1.5 0 0,1 6,18.5M20,8H17V4H3C1.89,4 1,4.89 1,6V17H3A3,3 0 0,0 6,20A3,3 0 0,0 9,17H15A3,3 0 0,0 18,20A3,3 0 0,0 21,17H23V12L20,8Z" />
                            </svg>
                            Dalej</p>
                    </OrderButton>
                </div>
            </div>

        </React.Fragment>
    )
}

export default Cart