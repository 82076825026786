import styled from 'styled-components';



export const ButtonSmall = styled.a`
    background-color: #00549D;
    align-items: center;
    justify-content: center;
    borderRadius: 15px;
    padding: 5px;
    margin:5px;
`

export const PriceInfo = styled.p`
color: #00549D;
flex-shrink: 1;
fontWeight: bold;
`


export const TextMain = styled.p`
color: #00549D;
`

export const H1 = styled.h1`
color: ${props => props.color ? props.color : "#95a5a6"};
fontWeight: bold;
margin:${props => props.marginH ? props.marginH : 10}px;
font-size:${props => props.size ? props.size : 20}px;
padding: ${props => props.padding ? props.padding : "0 0 0 5px"};
font-family: Calibri-Bold;
background:  ${props => props.background ? props.background : "none"};
`
export const H2 = styled.h2`
fontWeight: bold;
color: #00549D;
margin: ${props => props.margin ? props.margin : 10}px;
font-size:${props => props.size ? props.size : 20}px;
padding: ${props => props.padding ? props.padding : "0 0 0 5px"};
background:  ${props => props.background ? props.background : "none"};
font-family: Calibri-Bold;
`

export const ButtonBorder = styled.a`
borderRadius:15px;
borderColor:#ecf0f1;
borderWidth:1px;
padding:5px 40px;
flex-direction:row;
marginHorizontal:10px;
align-items:center;
`

export const SearchButton = styled.a`
display:flex;
flex-direction:row;
align-items:center;
backgroundColor: white;
border-radius: 15px;
border: 1px solid #ecf0f1;
padding:5px;
position:fixed;
top:10px;
left:10px;
right:10px;
background:white;
z-index:9999;
`
export const SearchInput = styled.div`
display:flex;
flex-direction:row;
align-items:center;
backgroundColor: white;
border-color: #ecf0f1;
border-width: 1px;
border-radius: 15px;
margin-left:15px;
`

export const RecentContainer = styled.div`
backgroundColor: white;
border-color: #ecf0f1;
border-width: 1px;
border-radius: 15px;
padding:10px;
margin:10px;
margin-left:15px;
`

export const Recent = styled.a`
align-items:center;
flex-direction:row;
`

export const Container = styled.div`
display:flex;
background:#fff;
margin: ${props => props.margin ? props.margin : 0}px;
flex-direction: ${props => props.row ? "row" : "column"};
alignContent: space-around;
justifyContent: space-evenly;
borderColor:#ecf0f1;
borderTopWidth: 1px;
borderBottomWidth: 1px;
borderBottomColor: #bdc3c7;
overflow:hidden;
padding-Bottom:80px;
`

export const SafeContainer = styled.div`
backgroundColor: white;
marginVertical: ${props => props.margin ? props.margin : 0}px;
flex-direction: ${props => props.row ? "row" : "column"};
paddingVertical: ${props => props.padding ? props.padding : "0"}px;
alignContent: space-around;
justifyContent: space-evenly;
borderColor:#ecf0f1;
borderTopWidth: 1px;
borderBottomWidth: 1px;
borderBottomColor: #bdc3c7;
marginBottom: ${props => props.bottom ? props.bottom : 0}px;
`
