import styled from 'styled-components';



export const ListsWrapper = styled.div`
margin: 55px 10px 0;

.selection {
    display:flex;
    justify-content:space-between;
}
`

export const BoxView = styled.div`
padding:20px 0;
width:48%;
border: ${props => props.active ? "1px solid #00549d" : "1px solid var(--borderGrey)"};
border-radius:10px;
display:flex;
justify-content:center;
background:#fff;
p{
    margin-left:5px;
}
ion-icon {
    color: ${props => props.active ? "#00549d" : "var(--main)"};
}
`

export const ModalWrapper = styled.div`
position:fixed;
top:0;
left:0;
width:100%;
height:100vh;
background: #f4f4f4;
z-index: 99999;
.top {
    display:flex;
    align-items:center;
    padding:10px;
    font-size:22px;
    font-weight:bold;
}
ion-icon {
    font-size:30px;
}
.preview {
    background:#fff;
    padding:20px 10px;
    display:flex;
    .imageWrapper{
        width:50px;
        height:50px;
        padding-right:10px;
        display: flex;
        align-self: center;
    }
    img {
        width:auto;
        max-width:50px;
        height: auto;
        max-height:50px;
    }
    .text{
        display:flex;
        flex-direction:column;
        width:100%;
        .attributes{
            padding:10px 0;
            display:flex;
            justify-content:space-between;
            font-size: 12px;
            color:#7f8c8d;
        }
        .prices{
            display:flex;
            p{
                margin-right:10px;
                font-weight:bold;
                font-size:20px;
            }
        }
    }
}

.new {
    background: #fff;
    padding:10px 0;
    margin-top:10px;
}

.withLists {
    background: #fff;
    padding:10px 0;
    margin-top:10px;
    .sectionHeader{
        display:flex;
        justify-content:space-between;
        align-items: center;
        border-bottom: 1px solid var(--borderGrey);
        padding-bottom:10px;
        button{
            margin-right:10px;
            background-color:#fff;
            border: 1px solid var(--borderGrey);
            border-radius: 15px;
            padding: 8px;
            height:32px;
            width: 130px;
            color: #00549D;
        }
    }
    .list-item{
        margin: 10px;
        border: 1px solid var(--title);
        border-radius:10px;
        display:flex;
        align-items:center;
        justify-content:space-between;
        span{
            margin-right:10px;
            display:flex;
            align-items:center;
            justify-content:space-between;
            width:80px;
        }
        h3{
            margin-left:10px;
        }
    }
}

.bottom {
    position:fixed;
    bottom:0;
    left:0;
    right:0;
    background:#fff;
    padding:20px 0;
    button {
        margin: 0 auto;
        display:block;
        background-color: ${props => props.bgColor ? "white" : "#00549D"};
        border: 1px solid #666;
        border-radius: 15px;
        padding: 8px;
        height:32px;
        width: 130px;
        color: #fff;
    }
}
`

export const ListOption = styled.div`
display:flex;
flex-direction: column;
padding:10px;

.singleList{
    background:#fff;
    border: 1px solid var(--borderGrey);
    border-radius: 10px;
    margin: 5px 0;
    .imagesWrapper{
        display:flex;
        padding:10px;
        img {
            width:auto;
            max-width:50px;
            height: auto;
            max-height:50px;
            border-radius:10px;
        }
    }
    .textWrapper{
        overflowX: auto;
        display:flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 10px;
        padding-bottom:10px;
        border-bottom: 1px solid var(--borderGrey);
        h3{
            margin:5px 0;
        }
    }
    .buttonsWrapper{
        margin:0px 10px 0;
        display:flex;
        justify-content:space-between;
    }

}
`
export const Btn = styled.button`
        background: ${props => props.bg ? "#fff" : "#00549D"};
        border: ${props => props.border ? "1px solid var(--borderGrey)" : "none"};
        border-radius: 15px;
        padding: 8px;
        height:32px;
        width: 100%;
        color: ${props => props.text ? props.text : "#fff"};
        margin:10px 5px;
`

export const EditWrapper = styled.div`
background:#fff;
header {
    background:#f4f4f4;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content: space-between;
    padding:10px 0;
    .showList{
        display:flex;
    flex-direction:row;
    align-items:center;
    }
    .settings{
    display:flex;
    flex-direction:row;
    align-items:center;
    padding-right:10px;
    ion-icon{
        margin:0 5px;
    }
    }
    
    ion-icon{
        font-size:25px;
    }
}
.bottom {
    position:fixed;
    bottom:70px;
    background:#fff;
    width:100%;
    height:50px;
    text-align:center;
    display:flex;
    align-items:center;
    justify-content:center;
}


`

export const ListNameInput = styled.input`
    font-size:22px;
    padding: 5px;
    background: ${props => props.edit ? "#fff" : "#f4f4f4"};
    border: ${props => props.edit ? "0 0 1px solid var(---borderGrey)" : "none"};
    outline:none;
    padding-left:20px;
`

export const ModalOption = styled.div`
position: fixed;
    top: 0;
    left: 0;
    background: #00000080;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .modal-body {
        background: #fff;
        flex-direction: column;
        display:flex;
        max-width:320px;
        min-width:120px;
        padding:10px;
        border-radius:10px;
        div {
            margin-top:10px;
            display:flex;
        }
    }
`