import React, { useState, useEffect } from "react";
import { H1, ProfileBtn, H2 } from "./style";
import { Axios } from "../tools/axios";
import { useNavigate } from "react-router-dom";
import Spinner from "../../assets/images/spinner.gif"




const OrdersHistory = () => {
    const [mounted, setMounted] = useState(false)
    const [orders, setOrders] = useState([])
    let navigate = useNavigate();


    useEffect(() => {

        Axios.get('mzmapp/payments').then((response) => {
            console.log(response.data)
            // let sorted = []
            // for (const property in response.data.orders) {
            //     sorted.push(response.data.orders[property])
            // }
            // setOrders(sorted.sort((a, b) => a.pos - b.pos))
            setMounted(true)

        }).catch(err => { console.log(err) })
    }, [])

    return (
        <React.Fragment>
            {mounted ? <div>
                <div style={{ height: "100vh" }}>
                    <div style={{ display: "flex", alignItems: "center", padding: "10px 0" }}>
                        <ion-icon name="chevron-back" style={{ marginLeft: 10, color: "#666" }}
                            onClick={() => navigate('/')}></ion-icon><H1 style={{ margin: "0 0 0 10px" }}>Historia zamówień</H1>
                    </div>
                    <div style={{ background:"#fff",paddingBottom: "120px", borderBottom: "1px solid #bdc3c7", display: "flex", flexDirection: "column" }}>
                        
                    </div>
                    <div style={{ background: "#fff", borderBottom: "1px solid #bdc3c7", marginTop: 20 }}>

                    </div>
                </div>
            </div> : <div style={{ background: "#fff", display: "flex", justifyContent: "center", marginTop: 60 }}>
                <img src={Spinner} style={{ width: "100%", height: "100%", maxWidth: 80, maxHeight: 80 }}></img>
            </div>}
        </React.Fragment>
    )
}

export default OrdersHistory



