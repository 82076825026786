import React, { useState, useEffect } from "react";
import { Axios } from "../tools/axios";
import { Container, SearchButton, SearchInput, H1 } from "./styles";
import { useNavigate, useLocation } from "react-router-dom";

import Spinner from "../../assets/images/spinner.gif"

const SubCategory = () => {

    const [mounted, setMounted] = useState(false)
    const [data, setData] = useState({})
    const [name, setName] = useState('')
    const [id, setId] = useState('')
    let navigate = useNavigate();
    let location = useLocation();

    const cats = []

    useEffect(() => {
        let id = location.search.split("=")[1]
        Axios.get('mzmapp/categories').then((response) => {
            const categoriesData = response.data.rows[id].subs
            setName(response.data.rows[id].name)
            setData(categoriesData)
            setId(id)
            setMounted(true)
        }).catch(err => { console.log(err) });
        return function cleanup() { setMounted(false) }
    }, [])

    if (mounted === true) {
        for (const property in data) {
            cats.push(data[property])
        }
    }

    // const handleChangeCategory = (id, name) => {
    //     navigate(`category/${id}`, { id: id, name: name });
    // }

    return (
        <React.Fragment>
            <Container>
                <SearchButton onClick={() => navigate('/search')}>
                    <SearchInput>
                        <ion-icon style={{ marginRight: 20 }} name="search-outline"></ion-icon>
                        <p>Czego szukasz?</p>
                    </SearchInput>
                </SearchButton>
                <div style={{ display: "flex", marginTop: 90, justifyContent: "space-between" }}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "inherit", alignItems: "center" }}>
                        <ion-icon name="chevron-back" style={{ marginLeft: 10, color: "#666", fontSize: "20px" }}
                            onClick={() => navigate('/categories')}
                        ></ion-icon>
                        <H1 style={{ margin: 0 }} color={"var(--title)"}>{name}</H1>
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    {!mounted ?
                        <div style={{ background: "#fff", display: "flex", justifyContent: "center", height: "100%", width: "100%", alignItems: "center" }}>
                            <img src={Spinner} style={{ width: "100%", height: "100%", maxWidth: 80, maxHeight: 80 }}></img>
                        </div> :
                        <div style={{ display: "flex", width: "100%", height: "100%", flexDirection: "row", flexWrap: 'wrap', justifyContent: 'left', padding: 5 }}>
                            {Object.entries(cats).map(([key, value]) => {
                                return (
                                    <div
                                        onClick={() => { navigate(`/category/subcategory/products?mc=${value.id}`, { state: { id_main: id, id_sub: value.id, name: value.name } }) }}
                                        style={{ position: "relative", maxWidth: "122px", width: "100%", height: 122, margin: 1, backgroundColor: "white", justifyContent: "space-around", background: `url(${value.image})`, backgroundSize: "contain", backgroundRepeat: "no-repeat" }}
                                        key={key}>
                                        <div>
                                            <p style={{ position: "absolute", bottom: 10, padding: "0 10px", fontSize: 11, fontWeight: "bold", fontFamily: "Calibri bold", color: "#666" }}>{value.name}</p>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>}
                </div>
            </Container>
        </React.Fragment>
    )
}

export default SubCategory