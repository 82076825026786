import React, { useState, useEffect } from "react";
import { Axios } from "../tools/axios";
import { Container, SearchButton, SearchInput, H1 } from "./styles";
import { useNavigate } from "react-router-dom";
import BottomBar from "../bottomBar/bottomBar";
import Spinner from "../../assets/images/spinner.gif"

const Categories = () => {


    const [mounted, setMounted] = useState(false)
    const [data, setData] = useState({})
    let navigate = useNavigate();


    const cats = []

    useEffect(() => {
        Axios.get('mzmapp/categories').then((response) => {
            const categoriesData = response.data.rows
            setData(categoriesData)
            
            setMounted(true)

        }).catch(err => {
            console.log(err)
        });
        return function cleanup() {
            setMounted(false)
        }

    }, [])

   

    if (mounted === true) {
        for (const property in data) {
            cats.push(data[property])
        }
        let list  = cats.sort(function(a,b){
            return a.pos - b.pos;
        })
    }




    return (
        <React.Fragment>
            <Container>
                <SearchButton  onClick={()=> navigate('/search')}>
                    <SearchInput>
                        <ion-icon style={{ marginRight: 20 }} name="search-outline"></ion-icon>
                        <p>Czego szukasz?</p>
                    </SearchInput>
                </SearchButton>
                <H1 style={{ marginTop: 90 }}>Kategorie</H1>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    {!mounted ?
                        <div style={{ background: "#fff", display: "flex", justifyContent: "center", height:"100%", width:"100%", alignItems:"center" }}>
                            <img src={Spinner} style={{width:"100%",height:"100%", maxWidth: 80, maxHeight: 80 }}></img>
                        </div> :
                            <div style={{ paddingBottom:"120px",display: "flex", width: "100%", flexDirection: "row", flexWrap: 'wrap', justifyContent: 'center', margin:"0 4px" }}>
                                {Object.entries(cats).map(([key, value]) => {
                                    return (
                                        <div
                                            onClick={() =>
                                                navigate(`/category/subcategory?mc=${value.id}`, { state: { id: value.id, name: value.name, image: value.image } })}
                                            style={{ position: "relative", maxWidth: "120px", width:"100%", height: 120, maxHeight:"120px", margin: 1, background: `url(${value.image})`, backgroundSize:"contain",backgroundRepeat: "no-repeat", justifyContent: "space-around" }}
                                            key={key}>
                                            <div style={{ marginTop: 5, width: "100%", display: "flex", justifyContent: "center" }}>
                                                {/* <img src={value.image}
                                                    style={{ resizeMode: 'contain', maxWidth:"60px", maxHeight:"60px", width: "100%", height: "100%", alignSelf: "center" }} /> */}
                                            </div>
                                            <div>
                                                <p style={{ position: "absolute", bottom: 10, padding: "0 5px", fontSize: 11, fontWeight:"bold", fontFamily:"Calibri bold", color:"#fff" }}>{value.name}</p>
                                            </div>

                                        </div>
                                    )
                                })}
                            </div>}
                </div>

            </Container>
          
        </React.Fragment>
    )
}

export default Categories