import React, { useState, useEffect } from "react";
import { H1, ProfileBtn, H2 } from "./style";
import { Axios } from "../tools/axios";
import { useNavigate, useLocation } from "react-router-dom";
import Spinner from "../../assets/images/spinner.gif"
import ProductSmall from "../product/productSmall";


const Order = () => {
    const [mounted, setMounted] = useState(false)
    const [preview, setPreview] = useState({})
    let navigate = useNavigate();
    let location = useLocation();
    
    useEffect(() => {
        let id = location.search.split("=")[1]
        Axios.get('mzmapp/orders').then((response) => {
            setPreview(response.data.orders[id])
            setMounted(true)
        }).catch(err => {console.log(err)})
    }, [])

    const handleInvoice = () => {
        if (preview.invoice_url !== null) {
            window.open(`${preview.invoice_url}`,'_blank');
        }
    }
   
    return (
        <React.Fragment>
            {!mounted ? <div style={{ background: "#fff", display: "flex", justifyContent: "center", marginTop: 60 }}>
                <img src={Spinner} style={{ width: "100%", height: "100%", maxWidth: 80, maxHeight: 80 }}></img>
            </div> :
            <div>
            <div style={{ height: "100vh", paddingTop: 20 }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <ion-icon name="chevron-back" style={{ marginLeft: 10, color: "#666" }}
                        onClick={() => navigate('/profile/orders')}></ion-icon><p>{preview.id}</p>
                </div>
                <div style={{ borderBottom: "1px solid #bdc3c7", display: "flex", flexDirection: "column", background:"#fff", padding:10, marginTop:10 }}>
                    <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                    <H1 style={{margin:0}}>Adres:</H1> <p style={{width:"200px"}}>{preview.address}</p>
                    </div>
                </div>
                <div style={{ borderBottom: "1px solid #bdc3c7", display: "flex", flexDirection: "column", background:"#fff", padding:10, marginTop:10 }}>
                    <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                    <H1 style={{margin:0}}>Data:</H1> <p>{preview.delivery_date}</p>
                    </div>
                </div>
                <div style={{ borderBottom: "1px solid #bdc3c7", display: "flex", flexDirection: "column", background:"#fff", padding:10, marginTop:10 }}>
                    <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                    <H1 style={{margin:0}}>Koszt:</H1> <p>{preview.amount}zł</p>
                    </div>
                </div>
                <div style={{ borderBottom: "1px solid #bdc3c7", display: "flex", flexDirection: "column", background:"#fff", padding:10, marginTop:10 }}>
                    <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                    <H1 style={{margin:0}}>Status:</H1> <p>{preview.status}</p>
                    </div>
                </div>
                <div style={{ borderBottom: "1px solid #bdc3c7", display: "flex", flexDirection: "column", background:"#fff", padding:10, marginTop:10 }}>
                    <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                    <H1 style={{margin:0}}>Rodzaj rachunku:</H1> <p>{preview.invoice_type} 
                    <ion-icon style={{fontSize:20}} name="document-outline"
                    onClick={()=>{handleInvoice()}}
                    ></ion-icon></p>
                    </div>
                </div>
                <div>
                {!mounted ? <div style={{ backgroundColor: "white", borderBottom: "1px solid #bdc3c7", paddingBottom: 10,display: "flex", justifyContent: "center" }}>
                            <img src={Spinner} style={{width:"100%",height:"100%", maxWidth: 80, maxHeight: 80 }}></img>
                            </div> :
                            <>
                            {preview.cart && Object.keys(preview.cart).length > 0 &&
                                <div style={{ display: "flex", flexDirection: "column", width: "100%", overflow: "auto", alignItems: "stretch", paddingBottom:120, marginTop:10, background:"#fff"}}>
                                    {Object.keys(preview.cart).map(v => {
                                        return (
                                            <ProductSmall key={preview.cart[v].id} product={preview.cart[v]} preview={true} />)
                                    })}
                                </div>}</>}
                </div>
            </div>
            </div>}
        </React.Fragment>
    )
}

export default Order