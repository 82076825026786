import React, { useState, useEffect } from "react";
import { ModalWrapper } from "./styles";
import { H1 } from "../product/styles";
import { useDispatch, useSelector } from "react-redux";
import { isShown } from "../redux/generalSlice";
import CustomInput from "../user/components/custominput"
import { Axios } from "../tools/axios";





const ListModal = () => {

    const [mounted, setMounted] = useState(false)
    const dispatch = useDispatch()
    const product = useSelector(state => state.general.listProduct)
    const [lists, setLists] = useState({})
    const [count, setCount] = useState(1)
    const [newList, setNewList] = useState({
        name: {
            value: '',
            touched: false,
        },
    })
    const [favLists, setFavlists] = useState([])

    let test = {}

    useEffect(() => {
        Axios.get('mzmapp/favlist').then((response) => {
            setLists(response.data.fav_list)


            Object.keys(lists).map(element => {
                test[lists[element].name] = { count: 1 }
            })
            setFavlists(test)
            setMounted(true)
        }).catch(err => {
            console.log(err)
        })
    }, [])

    const handleInput = (inputType, text) => {

        setNewList({ [inputType]: { value: text, touched: true } })
    }

    const Close = () => {
        dispatch(isShown(false))
    }

    const handleAddList = () => {
        Axios.post('mzmapp/favlistadd', { "name": newList.name.value }).then((response) => {
        }).catch(err => {
            console.log(err)
        })
    }

    const Add = (prod_id, list_name, list_id) => {
        Axios.post('mzmapp/favlistproductadd', {"product_id":prod_id,"list_id":list_id,"count":1}).then((response) => {
            console.log(response.data)
        }).catch(err => {
            console.log(err)
        })
    }

    const Decrease = () => {

    }

    return (
        <ModalWrapper>
            {!mounted ? <></> :
                <>
                    <div className="top">
                        <ion-icon
                            onClick={() => { Close() }}
                            name="close-outline"></ion-icon>
                        <p>Dodaj do listy zakupowej</p>
                    </div>
                    <div className="preview">
                        <div className="imageWrapper">
                            <img src={product.image}></img>
                        </div>

                        <div className="text">
                            <p>{product.brand} {product.name}</p>
                            {Object.keys(product.attr).length > 0 &&
                                <div className="attributes">
                                    <p>{product.attr[0].l}</p><p>{product.attr[0].r}</p>
                                </div>
                            }
                            <div className="prices">
                                <p>{product.price}</p>{product.price_old && <p style={{ textDecoration: "line-through" }}>{product.price_old}</p>}
                            </div>
                        </div>
                    </div>
                    {Object.keys(lists).length === 0 ?
                        <div className="new">
                            <H1>Utwórz nową listę</H1>
                            <CustomInput form={newList.name} type={"name"} name={"Nazwa listy"} handleInput={handleInput} />
                        </div> :
                        <div className="withLists">
                            <div className="sectionHeader">
                                <H1>Listy zakupowe</H1> <button>Utwórz nową listę</button>
                            </div>
                            {Object.keys(lists).map(item => {
                                return (

                                    <div className="list-item" key={lists[item].id}>
                                        <h3>{lists[item].name}</h3>
                                        <span>
                                            {count > 1 ? <ion-icon style={{ color: "#666", fontSize: "20px" }} name="remove-circle"
                                                onClick={() => { Decrease(product) }}></ion-icon> :
                                                <ion-icon style={{ color: "#666", fontSize: "20px" }} name="trash-bin"
                                                    onClick={() => { Decrease(product) }}
                                                ></ion-icon>}
                                            {count}
                                            <ion-icon style={{ color: "#00549D", fontSize: "20px" }} name="add-circle"
                                                onClick={() => { Add(product.id, lists[item].name, lists[item].id) }}
                                            ></ion-icon>
                                        </span>
                                    </div>
                                )
                            })}
                        </div>}
                    <div className="bottom">
                        <button
                            onClick={() => { handleAddList() }}
                        >Utwórz listę</button>
                    </div>
                </>}
        </ModalWrapper>
    )
}

export default ListModal