
import styled from 'styled-components';

export const LongWrapper = styled.div`
background:#fff;
display:flex;
position:relative;
padding:10px 5px 0px;
flex-direction:column;
justifyContent:space-around;
height:245px;
maxHeight:200px; 
// margin:0 2px;
// border-right: 1px solid #ebebeb;
width:100%;
cursor: pointer;
.prices {
    position:absolute;
    bottom:35px;
    display:flex;
    align-items:center;
    margin:0 4px;
    span {
        margin: 5px 0;
        font-weight:bold;
        font-size:20px;
        color:${props => props.price_old ? '#ff0000' : "#666"};
    }
    .old {
        margin: 0 5px;
        font-weight:bold;
        font-size:16px;
        color:#666;
        left:50px;
        text-decoration: line-through;
    }
}`

export const AddWrapper = styled.div`
position:absolute;
top:10px;
left:10px;
z-index:9995;
ion-icon {
    margin-right:4px;
}
span{
    font-size:12px;
    background:#fff;
    padding:10px;
    bottom:-10px;
    width:100%;
    position: ${props => props.long ? 'absolute' : "unset"};
    bottom: ${props => props.long ? '-45px' : "0"};
    width: ${props => props.long ? '150px' : "auto"};
    left: ${props => props.long ? '-10px' : "auto"};
    border: 1px solid var(--borderGrey);
    border-radius:10px;
}
`

export const Prices = styled.div`
position:absolute;
bottom:0;
display:flex;
align-items:center;
margin:0 4px;
span {
    margin: 5px 0;
    font-weight:bold;
    font-size:20px;
    color:${props => props.price_old ? '#ff0000' : "#666"};
}
.old {
    margin: 0 5px;
    font-weight:bold;
    font-size:16px;
    color:#666;
    left:50px;
    text-decoration: line-through;
}
}`

export const PriceBigWrapper = styled.div`
display: flex;
flex-direction: column;
align-items: center;
margin: 0px;
padding-right: 10px;
span{
    color:${props => props.price_old ? '#ff0000' : "#666"};
    font-weight: bold;
    margin: 0;
    font-size: 20px;
}
p{
    color:${props => props.price_old ? '#ff0000' : "#666"};
    font-weight: bold;
}

`

export const PriceBig = styled.p`
color:${props => props.price_old ? '#ff0000' : "#666"};
font-weight: bold;
margin: 10px 20px;
font-size: 30px;
margin-right: 10px;
`

export const ProductBlock = styled.div`
background: #fff;
width:160px;
color:#666;
margin:0 4px;
div {
    width:50px;
    height:50px;
    margin-bottom: 10px;
    margin: 0 auto 10px auto;
    display:block;
}

div > img {
    width:100%;
    height:100%;
    max-width:50px;
    max-height:50px;
    
    
}

p {
  display:block;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  height:40px;
  margin:0;
  font-size:12px;
  
}

h3{
    font-weight:bold;
    font-size:14px;
    font-family: Calibri-bold;
    margin: 10px 0 0 0;
}
`

export const Btn = styled.button`
width:100%;
position: absolute;

bottom:0;
border:none;
min-width: 120px;
max-width: 160px;
height: 32px;
border-radius: 16px;
background: #00549D;
cursor: pointer;
ion-icon {
    color: #fff;
  }
`

export const BtnAdd = styled.div`
width: 100%;
position: absolute;
min-width: 120px;
max-width: 160px;
height: 32px;
border-radius: 16px;
display:flex;
align-items:center;
bottom:0;
border:1px solid grey;
flex-direction: row;
justify-content: space-around;
background:#ffF
`

export const BtnBig = styled.button`
width:100%;
max-width:200px;
position: absolute;
padding:8px 10px;
bottom:0;
left:auto;
border:none;
background: #00549D;
border-radius:5px;
cursor: pointer;
ion-icon {
    color: #fff;
  }
`

export const BtnAddBig = styled.div`
width: 100%;
max-width: 200px;
position: absolute;
bottom:0;
left:auto;
padding:10px;
border:1px solid grey;
border-radius:5px;
align-items:"center";
display: flex;
flex-direction: row;
justify-content: space-around;
background:#ffF
`


export const Atrr = styled.div`
div{
    display: flex;
    justify-content: space-between;
    padding:0 1px;
    margin:0 4px;
}
p{
    color:#7f8c8d;
    font-size:12px;
}
`

export const H1 = styled.h1`
color: ${props => props.color ? props.color : "#00549D"};
font-weight: bold;
margin:10px 20px;
font-size:20px;
`
export const H2 = styled.h2`
color: ${props => props.color ? props.color : "#95a5a6"};
font-weight: bold;
margin:10px 20px;
`

export const SearchButton = styled.a`
display:flex;
flex-direction:row;
align-items:center;
backgroundColor: white;
border-radius: 15px;
border: 1px solid #ecf0f1;
padding:5px;
position:fixed;
top:10px;
left:10px;
right:10px;
background:white;
z-index: 9999;
`
export const SearchInput = styled.div`
display:flex;
flex-direction:row;
align-items:center;
backgroundColor: white;
border-color: #ecf0f1;
border-width: 1px;
border-radius: 15px;
margin-left:15px;
width:100%;
`