import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from "react-router-dom";

import { addProduct, favsList, isShown, listProduct, isModalText, isModal } from "../redux/generalSlice";
import { Axios, storeData } from "../tools/axios";
import { LongWrapper, Btn, ProductBlock, Atrr, BtnAdd, AddWrapper } from "./styles"


const ProductLong = (props) => {

    const [clicked, setClicked] = useState(false)
    const [count, setCount] = useState(1)
    const [fav, setFav] = useState(false)
    const dispatch = useDispatch()
    const [show, setShow] = useState(false)
    const [onlyLogged, setOnlyLogged] = useState(false)
    const [debounce, setDebounce] = useState(false)
    const test = useSelector(state => state.general)
    const favProds = useSelector(state => state.general.favs)
    const isLogged = useSelector(state => state.general.isLogged)

    const { product } = props
    let navigate = useNavigate();

    useEffect(() => {
        if (product.count >= 1) {
            setCount(parseInt(product.count))
            setClicked(true)
        } else {
            setClicked(false)
        }
        if (favProds) {
            Object.keys(favProds).map(prod => {
                if (prod === product.id) setFav(true)
            })
        }
    }, [])

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (count >= 1 && debounce) {
                const data = { "product_id": product.id, "count": parseInt(count) }
                Axios.post('mzmapp/setcart', data).then((response) => {
                    const res = response.data.rows
                }).catch(err => {
                    console.log(err)
                })
            }
        }, 600)

        return () => clearTimeout(delayDebounceFn)
    }, [count])

    const handleAddProduct = (product) => {
        setClicked(true)
        const data = { "product_id": product.id, "count": "1" }

        Axios.post('mzmapp/setcart', data).then((response) => {
            const newData = response.data

            if (newData.err) {
                dispatch(isModalText(newData.err.product_id))
                dispatch(isModal(true))
                setClicked(false)
            }
            const token = newData.token
            storeData(`${token}`)
            dispatch(addProduct(newData.cart.vcosts_products))
        }).catch(err => {
            console.log(err)
        })
    }


    const Add = (product) => {
        setDebounce(true)
        setCount(prevCount => prevCount + 1)

        // const data = { "product_id": product.id, "count": parseInt(count) + 1 }

        // const timer = setTimeout(() => {

        //     Axios.post('mzmapp/setcart', data).then((response) => {
        //         const newData = response.data

        //         const token = newData.token
        //         storeData(`${token}`)
        //         dispatch(addProduct(newData.cart.vcosts_products))
        //     }).catch(err => {
        //         console.log(err)
        //     })
        // }, 1000);
    }

    const Decrease = (product) => {
        setDebounce(true)
        if (count === 1) {
            const data = { "product_id": product.id, "count": 0 }
            Axios.post('mzmapp/setcart', data).then((response) => {
                const newData = response.data
                const token = newData.token
                storeData(`${token}`)
                dispatch(addProduct(newData.cart.vcosts_products))
            }).catch(err => {
                console.log(err)
            })
            setClicked(false)

        } else if (count === 0 || count < 0) {
            setClicked(false)
            setCount(1)
        }
        else {
            setCount(prevCount => prevCount - 1)

            // const data = { "product_id": product.id, "count": parseInt(count) - 1 }

            // const timer = setTimeout(() => {

            //     Axios.post('mzmapp/setcart', data).then((response) => {
            //         const newData = response.data
            //         const token = newData.token
            //         storeData(`${token}`)
            //         dispatch(addProduct(newData.cart.vcosts_products))
            //     }).catch(err => {
            //         console.log(err)
            //     })
            // }, 1000);
        }
    }

    const handleFav = (prodId) => {
        if (!fav && isLogged) {
            setFav(true)
            Axios.post('mzmapp/favadd', { "product_id": prodId }).then((response) => {
                dispatch(favsList(response.data.fav_products))
            }).catch(err => {
                console.log(err)
            })
        } else if (fav && isLogged) {
            setFav(false)
            Axios.post('mzmapp/favremove', { "product_id": prodId }).then((response) => {
                dispatch(favsList(response.data.fav_products))

            }).catch(err => {
                console.log(err)
            })
        } else {
            setOnlyLogged(true)
            setTimeout(() => {
                setOnlyLogged(false)
            }, 3000)
        }
    }

    const handleList = (p) => {

        if (isLogged) {
            dispatch(listProduct(p))
            dispatch(isShown(true))
        } else {
            setOnlyLogged(true)
            setTimeout(() => {
                setOnlyLogged(false)
            }, 3000)
        }
    }


    return (
        <LongWrapper price_old={product.price_old}>
            <AddWrapper long>
                {!fav ?
                    <ion-icon name="star-outline"
                        onClick={() => { handleFav(product.id) }}
                    ></ion-icon> :
                    <ion-icon name="star" style={{ color: "#00549d" }}
                        onClick={() => { handleFav(product.id) }}
                    ></ion-icon>}


                <ion-icon name="reader-outline"
                    onClick={() => { handleList(product) }}
                ></ion-icon>
                {onlyLogged && <span>opcja dostępna tylko dla zalogowanych</span>}
            </AddWrapper>

            <ProductBlock onClick={() => { navigate(`/product?mp=${product.id}`, { state: { id: product.id } }) }}>
                <div>
                    <img src={product.image} style={{ resizeMode: 'contain', maxWidth: 50, width: "auto", height: "auto", maxHeight: 50, justifyContent: "center" }} />
                </div>


                <h3>{product.brand}</h3>
                <p>{product.name}</p>

            </ProductBlock>
            {(Object.keys(product.attr).length > 0) &&
                <Atrr><div><p>{product.attr[0].l}</p> <p>{product.attr[0].r}</p></div></Atrr>}
            {product.html_in ? <div style={{ margin: "5px 0" }} dangerouslySetInnerHTML={{ __html: product.html_in }} /> : <div style={{ height: "14px", padding: 10 }}></div>}
            <div className="prices">
                <span>{product.price}zł</span>{product.price_old && <p className="old">{product.price_old}zł</p>}
            </div>
            {!clicked ?
                <Btn onClick={() => { handleAddProduct(product) }}>
                    <svg xmlns="http://www.w3.org/2000/svg" style={{ width: "20px", height: "20px", fill: "#fff" }} viewBox="0 0 26.81 24.3" ><defs /><g id="Warstwa_2" data-name="Warstwa 2"><g id="pasek_gora"><path d="M26.4,9.15a2.29,2.29,0,0,0-1.64-1L16,.21a.78.78,0,0,0-1.11.05.79.79,0,0,0,0,1.12l7.46,6.77h-18l7.46-6.77A.79.79,0,1,0,10.79.21L2,8.17A2.3,2.3,0,0,0,.13,11.22L4.24,22.77A2.3,2.3,0,0,0,6.41,24.3H20a2.32,2.32,0,0,0,2.15-1.47l4.46-11.55A2.28,2.28,0,0,0,26.4,9.15Zm-1.22,1.56L20.71,22.26a.7.7,0,0,1-.67.46H6.41a.71.71,0,0,1-.68-.48L1.62,10.69A.72.72,0,0,1,1.71,10a.7.7,0,0,1,.59-.31H24.51a.71.71,0,0,1,.59.32A.68.68,0,0,1,25.18,10.71Z" /><rect x="12.31" y="12.21" width="2.19" height="8.03" rx="0.8" /><rect x="17.68" y="12.21" width="2.19" height="8.03" rx="0.8" /><rect x="6.92" y="12.21" width="2.19" height="8.03" rx="0.8" /></g></g></svg></Btn> :
                <BtnAdd>
                    {count > 1 ? <ion-icon style={{ color: "#666", fontSize: "22px" }} name="remove-circle"
                        onClick={() => { Decrease(product) }}></ion-icon> :
                        <ion-icon style={{ color: "#666", fontSize: "22px" }} name="trash-bin"
                            onClick={() => { Decrease(product) }}
                        ></ion-icon>}

                    {count}
                    <ion-icon style={{ color: "#00549D", fontSize: "22px" }} name="add-circle"
                        onClick={() => { Add(product) }}
                    ></ion-icon>
                </BtnAdd>}
        </LongWrapper>
    )
}

export default ProductLong