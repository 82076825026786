import axios from "axios";

const url = 'https://anabru.pl/'


export const getData = () => {
    let token2 = `${localStorage.getItem('token')}`

}

export const storeData = (value) => {
    localStorage.setItem('token', value)
}


export const Axios = axios.create({
    baseURL: url,
    headers: {
        'Content-Type' : 'application/json;charset=UTF-8'
    },
    mode: 'no-cors',
    withCredentials: false,
    crossdomain:true,
})

Axios.interceptors.request.use (
    function (config) {
      const token = localStorage.getItem('token')
      if (token) config.headers.token = `${token}`;
      return config;
    },
    function (error) {
      return Promise.reject (error);
    }
  );