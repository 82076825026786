import React, { useEffect, useState } from "react";
import { Wrapper, Icon } from "./styles";
import { useNavigate } from "react-router-dom";
import { useSelector} from 'react-redux'
import cartImg from "../../assets/images/cart.svg"



const BottomBar = () => {

    const [active, setActive] = useState('profile')

    let navigate = useNavigate();
    const fullPrice = useSelector(state => state.general.totalPrice)

    let path = window.location.pathname

    

    useEffect(()=>{
        if (path === '/home'){
            setActive('home')
        }

    })

    const handleChangeRoute = (type) => {

        setActive(type)
        if (type === 'profile') {
            navigate(`/`)
        } else {
            navigate(`/${type}`)
        }
    }


    return (
        <React.Fragment>
            {path !== "/order" && <Wrapper>
                <Icon active={active === 'home'} border={active === 'home'}
                    onClick={() => { return (handleChangeRoute('home'), setActive('home')) }}>
                    <ion-icon name="home"></ion-icon>
                    <p>Główna</p></Icon>
                <Icon active={active === 'categories'} border={active === 'categories'}
                    onClick={() => { handleChangeRoute('categories') }}
                ><ion-icon name="grid-outline"></ion-icon>
                    <p>Kategorie</p></Icon>
                    
                <Icon active={active === 'cart'}
                    onClick={() => { handleChangeRoute('cart') }}>
                       <svg xmlns="http://www.w3.org/2000/svg" style={{fontSize:"25px",width:"100%", maxWidth:"25px", height:"100%", maxHeight:"25px"}} viewBox="0 0 26.81 24.3" ><defs/><g id="Warstwa_2" data-name="Warstwa 2"><g id="pasek_gora"><path d="M26.4,9.15a2.29,2.29,0,0,0-1.64-1L16,.21a.78.78,0,0,0-1.11.05.79.79,0,0,0,0,1.12l7.46,6.77h-18l7.46-6.77A.79.79,0,1,0,10.79.21L2,8.17A2.3,2.3,0,0,0,.13,11.22L4.24,22.77A2.3,2.3,0,0,0,6.41,24.3H20a2.32,2.32,0,0,0,2.15-1.47l4.46-11.55A2.28,2.28,0,0,0,26.4,9.15Zm-1.22,1.56L20.71,22.26a.7.7,0,0,1-.67.46H6.41a.71.71,0,0,1-.68-.48L1.62,10.69A.72.72,0,0,1,1.71,10a.7.7,0,0,1,.59-.31H24.51a.71.71,0,0,1,.59.32A.68.68,0,0,1,25.18,10.71Z"/><rect  x="12.31" y="12.21" width="2.19" height="8.03" rx="0.8"/><rect  x="17.68" y="12.21" width="2.19" height="8.03" rx="0.8"/><rect  x="6.92" y="12.21" width="2.19" height="8.03" rx="0.8"/></g></g></svg>
                    <p>Koszyk</p>
                   {fullPrice > 0 && <span>{fullPrice} zł</span>}
                    </Icon>
                <Icon active={active === 'lists'}
                onClick={() => { handleChangeRoute('lists') }}
            ><ion-icon name="reader-outline"></ion-icon>
                <p>Listy zakupowe</p></Icon>
                <Icon active={active === 'profile'} border={active === 'profile'}
                    onClick={() => { handleChangeRoute('profile') }}
                ><ion-icon name="person"></ion-icon>
                    <p>Profil</p></Icon>
            </Wrapper>}
        </React.Fragment>
    )
}

export default BottomBar