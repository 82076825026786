import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'

import { H1, H2, SearchButton, SearchInput, Container } from "./styles";
import ProductLong from "../product/productLong";
import { Axios, getData, storeData } from "./../tools/axios"
import Carousel from 'nuka-carousel';
import { priceUpdate, isLogged, favsList, setActiveAddress, isModalText, isModal } from "../redux/generalSlice";
import Spinner from "../../assets/images/spinner.gif"
import { useNavigate, useLocation } from "react-router-dom";
import ListModal from "../lists/listModal";
import Modal from "../modal/modal";






const Home = () => {

    const [mounted, setMounted] = useState(false)
    const [slides, setSlides] = useState({})
    const [promos, setPromos] = useState({})
    const [best, setBest] = useState({})
    const [newProd, setNewProd] = useState({})
    const dispatch = useDispatch()
    const isShown = useSelector(state => state.general.isShown)
    const isModalShow = useSelector(state => state.general.modal)
    const modalTxt = useSelector(state => state.general.modal_text)
    let navigate = useNavigate();
    let location = useLocation();

    


    useEffect(() => {
        if(location.search.length > 0 && location.search.split('?')[1] === 'thx=1'){
            dispatch(isModal(true))
            dispatch(isModalText('Dziękujemy za złożenie zamówienia.'))
        }
        getData()
        Axios.get('mzmapp/home').then((response) => {
            const newData = response.data
            const token = newData.token
            dispatch(isLogged(newData.user.logged))
            dispatch(setActiveAddress(newData.reservation.address_id))
            setSlides(response.data.slider_rows)
            setPromos(response.data.promotions_rows)
            setBest(response.data.bestsellers_rows)
            setNewProd(response.data.new_rows)
            storeData(`${token}`)
            dispatch(priceUpdate(parseFloat(newData.cart.vcosts_products)))
            setMounted(true)
        }).catch(err => {console.log(err)})
        Axios.get('mzmapp/fav').then((response) => {
            dispatch(favsList(response.data.fav_products))
        }).catch(err => {console.log(err)})
    }, [])

    const handlePromoClick = (slide) => {
        if (slide.category_id !== null) {
            navigate(`/category/subcategory/products?mc=${slide.category_id}`, {
                state: { id_main: slide.category_level_2, id_sub: slide.category_level_3, name: slide.title }
            })
        } else if (slide.url) {
            window.location.href = slide.url
        }
    }

    const handleClose = () => {
        dispatch(isModal(false))
    }


    return (
        <React.Fragment>
            <Container>
                {isShown && <ListModal />}
                {isModalShow && <Modal text={modalTxt} showModal={handleClose} />}
                <React.Fragment>
                    <div style={{ display: "flex", flexDirection: "column", background: "white", position: "fixed", width: "100%", zIndex: "9999" }}>
                        <SearchButton onClick={() => navigate('/search')}>
                            <SearchInput>
                                <ion-icon style={{ marginRight: 20 }} name="search-outline"></ion-icon>
                                <p style={{ color: "#666" }}>Czego szukasz?</p>
                            </SearchInput>
                        </SearchButton>
                    </div>

                    <div style={{ overflow: "hidden", marginTop: 50 }}>
                        <H1 marginH={"0"} padding={"10px"} color={"#00549D"} background={"var(--background)"}>Witaj!</H1>
                        {!mounted ?
                            <div style={{ background: "#fff", display: "flex", justifyContent: "center" }}>
                                <img src={Spinner} style={{ width: "100%", height: "100%", maxWidth: 80, maxHeight: 80 }}></img>
                            </div> :
                            <div>
                                <Carousel autoplay={true} >
                                    {Object.keys(slides).map(v => {    
                                        return (
                                            <img
                                                onClick={() => { handlePromoClick(slides[v]) }}
                                                key={slides[v].title} src={slides[v].image} />)
                                    })}
                                </Carousel>

                            </div>}
                        <div>
                            <H2 margin={"0"} padding={"10px"} background={"var(--background)"}>Promocje</H2>
                            {!mounted ? <div style={{ backgroundColor: "white", borderBottom: "1px solid #bdc3c7", paddingBottom: 10, display: "flex", justifyContent: "center" }}>
                                <img src={Spinner} style={{ background: "#fff", width: "100%", height: "100%", maxWidth: 80, maxHeight: 80 }}></img>
                            </div> :
                                <div style={{ display: "flex", flexDirection: "row", width: "100%", overflow: "auto", alignItems: "stretch" }}>
                                    {Object.keys(promos).map(v => {
                                        return (
                                            <ProductLong key={promos[v].id} product={promos[v]} />)
                                    })}
                                </div>}
                        </div>
                        <div style={{ borderBottom: "1px solid #bdc3c7" }}>
                            <H2 margin={"0"} padding={"10px"} background={"var(--background)"}>Bestsellery</H2>
                            {!mounted ? <div style={{ backgroundColor: "#fff", alignItems: "center", display: "flex", justifyContent: "center" }}>
                                <img src={Spinner} style={{ width: "100%", height: "100%", maxWidth: 80, maxHeight: 80 }}></img>
                            </div> :
                                <div style={{ background: "#fff", display: "flex", flexDirection: "row", width: "100%", overflow: "auto" }}>
                                    {Object.keys(best).map(v => {
                                        return (
                                            <ProductLong key={best[v].id} product={best[v]} />)
                                    })}
                                </div>}
                        </div>
                        <div style={{marginBottom:"20px"}}>
                            <H2 margin={"0"} padding={"10px"} background={"var(--background)"}>Nowości</H2>
                            {!mounted ? <div style={{ backgroundColor: "#fff", alignItems: "center", display: "flex", justifyContent: "center" }}>
                                <img src={Spinner} style={{ width: "100%", height: "100%", maxWidth: 80, maxHeight: 80 }}></img>
                            </div> :
                                <div style={{ background: "#fff", display: "flex", flexDirection: "row", width: "100%", overflow: "auto" }}>
                                    {Object.keys(newProd).map(v => {
                                        return (
                                            <ProductLong key={newProd[v].id} product={newProd[v]} />

                                        )
                                    })}
                                </div>}
                        </div>
                    </div>
                </React.Fragment>

            </Container>

        </React.Fragment>
    );
}


export default Home