import React, { useState, useEffect } from "react";
import { H1, ProfileBtn, H2, AddressListWrapper, AddressBox, Btn } from "./style";
import { Axios } from "../tools/axios";

import Spinner from "../../assets/images/spinner.gif"
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { addressesList, setActiveAddress } from "../redux/generalSlice";
import EditAddress from "./editAddress"
import Modal from "../modal/modal";




const AddressList = (props) => {
    const [mounted, setMounted] = useState(true)
    const [address, setAddress] = useState({})
    const [active, setActive] = useState('')
    const [isHidden, setIsHidden] = useState(true)
    const [show, setShow] = useState([])
    const [showEdit, setShowEdit] = useState(false)
    const [formData, setFormData] = useState({})
    const [id, setId] = useState('')
    const [title, setTitle] = useState('')
    const [showM, setShowM] = useState(false)
    const addreses = useSelector(state => state.general.addreses)
    let dispatch = useDispatch();
    let navigate = useNavigate();

    const { OrderHandleClose, order } = props

    useEffect(() => {

        Axios.get('mzmapp/address').then((response) => {
            const res = response.data.address
            dispatch(addressesList(res))
            setAddress(res)
        }).catch(err => {
            console.log(err)
        })

        setMounted(true)

    }, [])

    const handleOpen = (addressId) => {

        setShow({ ...show, [addressId]: { show: true } })
        setIsHidden(!isHidden)
    }

    const handleDelete = (addressId) => {

        Axios.post('mzmapp/addressremove', { "address_id": addressId }).then((response) => {
            const res = response.data.address
            dispatch(addressesList(res))
        }).catch(err => {
            console.log(err)
        })
    }

    const handleEdit = (item, id) => {
        setFormData(item)
        setId(id)
        setTitle('Edytuj adres')
        setShowEdit(true)
    }

    const handleClose = () => {
        setShowEdit(false)
    }

    const handleNew = () => {
        setFormData({})
        setShowEdit(true)
        setTitle('Dodaj adres')
    }

    const selectAddress = () => {
        Axios.post('mzmapp/addressset', { "address_id": active, "reservation_id": 1 }).then((response) => {
            const res = response.data
            setShowM(true)
            dispatch(setActiveAddress(active))
        }).catch(err => {
            console.log(err)
        })
    }

    const showModal = () => {
        setShowM(!showM)
        if(props.fromForm){
            OrderHandleClose('my')
        }
    }


    return (
        <React.Fragment>
            {showM && <Modal showModal={showModal} text={'Adres zmieniony'} />}
            {showEdit && <EditAddress handleClose={handleClose} id={id} formData={formData} title={title} />}
            {mounted ? <div>
                <AddressListWrapper>
                    <header>
                        <ion-icon name="chevron-back" style={{ marginLeft: 10, color: "#666" }}
                            onClick={() => {
                                !order ? navigate('/') : OrderHandleClose('my')
                            }}
                        ></ion-icon><h3>Adresy dostawy</h3>
                    </header>

                    <div style={{ background: "#fff", borderBottom: "1px solid #bdc3c7", display: "flex", justifyContent: "center", padding: "10px 0" }}>

                        <ProfileBtn
                            onClick={() => { handleNew() }}
                            bgColor={"#fff"}><ion-icon style={{ fontSize: 25, marginRight: 10 }} name="navigate-circle-outline"></ion-icon> Nowy adres</ProfileBtn>
                    </div>
                    <div className="addressLists">
                        <div style={{marginBottom:"300px"}}>
                            {Object.keys(addreses).map(item => {
                                return (
                                    <AddressBox active={active === addreses[item].id} key={addreses[item].id}
                                        onClick={() => { setActive(addreses[item].id) }}
                                    >
                                        <ul>
                                            <li className="withBtn">
                                                <div>
                                                    <p>Adres Dostawy</p>
                                                    <span>ul. {addreses[item].street} {addreses[item].street_no}/{addreses[item].street_no2}</span>
                                                    <span>{addreses[item].postcode} {addreses[item].city}</span>
                                                </div>
                                                {!isHidden ? <ion-icon name="chevron-up"
                                                    onClick={() => { handleOpen(addreses[item].id) }}
                                                ></ion-icon> :
                                                    <ion-icon name="chevron-down"
                                                        onClick={() => { handleOpen(addreses[item].id) }}
                                                    ></ion-icon>}
                                            </li>
                                            {!isHidden &&
                                                <>
                                                    <li className="hidden">
                                                        <p>Imię i nazwisko</p>
                                                        <span>{addreses[item].name}</span>
                                                    </li>
                                                    <li className="hidden">
                                                        <p>Numer telefonu</p>
                                                        <span>{addreses[item].phone}</span>
                                                    </li>
                                                </>}
                                        </ul>
                                        <div className="buttonsWrap">
                                            <Btn
                                                onClick={() => { handleDelete(addreses[item].id) }}
                                                bgColor={"#fff"}>usuń</Btn>
                                            <Btn
                                                onClick={() => { handleEdit(addreses[item], addreses[item].id) }}
                                                color={"#fff"}>edytuj</Btn>
                                        </div>
                                    </AddressBox>
                                )
                            })}
                        </div>

                    </div>
                    <footer>
                        <Btn onClick={() => { selectAddress() }}
                            color={"#fff"}>Zatwiedź</Btn>
                    </footer>
                </AddressListWrapper>
            </div> : <></>}
        </React.Fragment>
    )
}

export default AddressList



