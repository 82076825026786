import React, { useState, useEffect } from "react";
import { ModalMain, ModalBody } from "./style";



const Modal = (props) => {

    const { showModal } = props
    return (
        <ModalMain>
            <ModalBody>
                {props.title && <p>{props.title}</p>}
                {props.text && <p>{props.text}</p>}
                {props.erros &&
                    <div className="errors">
                        {Object.keys(props.errors).map((item) => {
                            <p key={item}>{props.errors[item]}</p>
                        })}
                    </div>}
                <div>
                    <div className="button"
                        onClick={() => { showModal() }}
                    >zamknij</div>
                </div>
            </ModalBody>
        </ModalMain>
    )
}

export default Modal