import styled from 'styled-components';



export const ModalMain = styled.div`
position: fixed;
top: 0;
left: 0;
background: #00000075;
width: 100%;
height: 100%;
z-index: 99999;
display: flex;
justify-content: center;
align-items: center;
`

export const ModalBody = styled.div`
display:flex;
flex-direction:column;
background: #fff;
width: 100%;
height: 100%;
max-width:260px;
min-height:120px;
max-height:140px;
justify-content: center;
align-items: center;
border-radius:15px;
p {
    margin:10px 0;
    padding: 0 10px;
}
.errors{
    display:flex;
    flex-direction:column;
    p{
        margin:5px 0;
    }
}
.button{
    background:rgb(0, 84, 157);
    padding:2px 10px;
    border-radius:15px;
    color:#fff;
}
`