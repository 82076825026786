import React, { useState, useEffect } from "react";
import { Axios } from "../tools/axios";
import { SearchButton, SearchInput } from "../product/styles";
import Spinner from "../../assets/images/spinner.gif"
import ProductSmall from "../product/productSmall";


const Search = () => {

    const [prodName, setProdName] = useState(' ')
    const [products, setProducts] = useState({})
    const [mounted, setMounted] = useState(false)
    const [startSearch, setStartSearch] = useState(false)

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
          if (prodName) {
           Axios.get(`mzmapp/products?str=${prodName}`).then((response) => {
            const res = response.data.rows
        
            setProducts(res)
            setMounted(true)
            setStartSearch(false)
        }).catch(err => {
            console.log(err)
        })
          }
        }, 300)
      
        return () => clearTimeout(delayDebounceFn)
      }, [prodName])

    const handleSearch = (value) => {
        if (value) {
            setStartSearch(true)
            setProdName(value)
          }
    }


    return (
        <React.Fragment>
            <div>
                <div>
                    <SearchButton>
                        <SearchInput>
                            <ion-icon style={{ marginRight: 20 }} name="search-outline"></ion-icon>
                            <input
                                placeholder="Czego szukasz?"
                                value={prodName}
                                onChange={(e) => { handleSearch(e.target.value) }}
                                style={{ width: "100%", border: "none", outline: "none" }}>
                            </input>

                        </SearchInput>
                    </SearchButton>
                </div>
            </div>
            <div>
            {startSearch ? 
                            <div style={{padding:"80px 0", background: "#fff", display: "flex", justifyContent: "center", height:"100%", width:"100%", alignItems:"center" }}>
                                <img src={Spinner} style={{width:"100%",height:"100%", maxWidth: 80, maxHeight: 80 }}></img>
                            </div> :

                        <div style={{ padding:"40px 0 80px",backgroundColor: "#fff", marginTop: 10, overflowY: "hidden" }}>


                            {Object.keys(products).map(v => {
                                return (
                                    <ProductSmall key={products[v].id} product={products[v]} />)
                            })}

                        </div>}
            </div>
            
        </React.Fragment>
    )
}

export default Search



