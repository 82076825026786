import styled from 'styled-components';


export const InputWrapper = styled.div`
display:flex;
flex-direction:row;
align-items:center;
background: white;
border: 1px solid;
color:#666;

    border-width: 1px;
    border-radius: 25px;
    padding: ${props => props.register ? "8" : "5"}px;
    margin: 5px 10px;
    border-color: ${props => props.error ? "red" : "#ecf0f1"};
    width:${props => props.width ? props.width : "auto"};

input {
    border:none;
    outline:none;
    &:focus{
        border:none;
    }
}
`

export const ButtonSmall = styled.div`
    background-color: ${props => props.bgColor ? props.bgColor : "#00549D"};
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    padding: 8px;
    margin:5px;
    position:absolute;
    bottom:-20px;
    width: 200px;
    color: ${props => props.color ? props.color : "#00549D"};
`
export const H1 = styled.h1`
color: ${props => props.color ? props.color : "#00549D"};
font-weight: bold;
margin:10px 20px;
font-size:20px;
font-family: Calibri-Bold;
`
export const H2 = styled.h2`
color: ${props => props.color ? props.color : "#95a5a6"};
font-weight: bold;
margin:10px 20px;
`

export const P = styled.p`
margin:10px 20px;
color: #666
`

export const InputLabel = styled.p`
margin-left: 30px;
font-size: 10px;
color:#95a5a6;
`

export const ProfileBtn = styled.div`
    background-color: ${props => props.bgColor ? "white" : "#00549D"};
    display:flex;
    border: 1px solid var(--borderGrey);
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    padding: 8px;
    margin:5px;
    height:15px;
    width: 150px;
    color: ${props => props.color ? props.color : "#00549D"};
`

export const EditWrapper = styled.div`
position: fixed;
top:0;
left:0;
z-index: 99991;
background: #fff;
width: 100%;
height: 100%;
header {
    background:#f4f4f4;
    display:flex;
    align-items:center;
    h3{
        font-size:22px;
    }
    ion-icon{
        font-size:25px;
    }
}
h4 {
    color:#00549D;
    font-family: Calibri-Bold;
    margin:0 20px 10px;
}
`

export const AddressListWrapper = styled.div`
height:100%;
position:fixed;
top:0;
left:0;
right:0;
z-index:9999;

background: var(--background);

header {
    display:flex;
    align-items:center;
    h3{
        font-size:22px;
        margin:10px;
    }
    ion-icon{
        font-size:25px;
    }
}
.addressLists{
    height:100%;
    background:#fff;
    margin-top:10px;
    padding:10px 0;
    overflow: auto;
}
footer {
    position:fixed;
    background: var(--background);
    bottom:70px;
    left:0;
    right:0;
    padding:10px;
    text-align:center;
    border-top:1px solid var(--borderGrey)
}

`

export const AddressBox = styled.div`
margin:10px;
border:  ${props => props.active ? "1px solid #00549D" : "1px solid var(--borderGrey)"};
border-radius:10px;
ul {
    padding: 10px 20px;
    margin: 0;
    li {
        list-style:none;
        display:flex;
        flex-direction:column;
        padding: 10px 0;
        span{
            font-weight:bold;
            font-family: Calibri-Bold;
        }
        div {
            display:flex;
            flex-direction:column;
        }
}
.withBtn{
    justify-content:space-between;
    flex-direction:row;
    ion-icon{
        font-size:25px;
    }
}
    
}
.hidden {
    border-top: 1px solid var(--borderGrey);
}

.buttonsWrap{
    display:flex;
    justify-content:space-between;
    padding-bottom:10px;
}
`

export const Btn = styled.button`
    background-color: ${props => props.bgColor ? props.bgColor : "#00549D"};
    border: 1px solid var(--borderGrey);
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    padding: 8px;
    margin:5px;
    width: 200px;
    color: ${props => props.color ? props.color : "#00549D"};
`