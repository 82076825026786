import React, { useState, useEffect } from "react";
import { H1, ButtonSmall, H2 } from "./style";
import { Axios, storeData } from "../tools/axios";
import { useDispatch } from "react-redux";
import { isLogged, priceUpdate } from "../redux/generalSlice";
import { useNavigate } from "react-router-dom";


const Dashboard = () => {

    const [mounted, setMounted] = useState(false)
    const [user, setUser] = useState({})
    const dispatch = useDispatch()
    let navigate = useNavigate();



    useEffect(() => {
        Axios.get('mzmapp/user?reservation_id=1').then((response) => {
            const res = response.data
            setUser(res.user)


            setMounted(true)
        }).catch(err => {
            console.log(err)
        }); return () => {
            setMounted(false)
            setUser('')
        }

    }, [])


    const handleLogout = () => {
        dispatch(isLogged(false))
        dispatch(priceUpdate(0))
        Axios.get('mzmapp/regeneratesesid').then((response) => {
            const res = response.data
            if (!res.err) {
                storeData(``)
                setTimeout(() => {
                    navigate('/')
                }, 500)
            }
        }).catch(err => { console.log(err) })
    }


    return (
        <React.Fragment>
            {mounted ? <div>
                <div style={{ height: "100vh", paddingTop: 50 }}>
                    <H1>Witaj,  {user.name}!</H1>
                    <div style={{ borderBottom: "1px solid #bdc3c7" }}>
                        <div style={{ borderBottom: "1px solid var(--borderGrey)", marginTop: 10, background: "#fff", display: "flex", alignItems: "center", justifyContent: "space-between" }}
                            onClick={() => { navigate(`/profile/orders`) }}>
                            <H2 style={{ fontSize: 16, color: "#00549D", padding: "5px 0" }}>Zamówienia</H2>
                            <ion-icon style={{ fontSize: 20, color: "#666", paddingRight: 20 }} name="chevron-forward"></ion-icon></div>
                        <div style={{ borderBottom: "1px solid var(--borderGrey)", marginTop: 10, background: "#fff", display: "flex", alignItems: "center", justifyContent: "space-between" }}
                            onClick={() => { navigate(`/profile/address`) }}>
                            <H2 style={{ fontSize: 16, color: "#00549D", padding: "5px 0" }}>Adresy dostawy</H2>
                            <ion-icon style={{ fontSize: 20, color: "#666", paddingRight: 20 }} name="chevron-forward"></ion-icon></div>
                        {/* <div style={{ borderBottom: "1px solid var(--borderGrey)", marginTop: 10, background: "#fff", display: "flex", alignItems: "center", justifyContent: "space-between" }}
                            onClick={() => { navigate(`/profile/orders-history`) }}>
                            <H2 style={{ fontSize: 16, color: "#00549D", padding: "5px 0" }}>Historia zamówień</H2>
                            <ion-icon style={{ fontSize: 20, color: "#666", paddingRight: 20 }} name="chevron-forward"></ion-icon></div> */}
                        <div style={{ background: "#fff", height: 50, position: "relative", display: "flex", justifyContent: "center", textAlign: "center", marginTop: 10 }}>
                            <ButtonSmall onClick={() => { handleLogout() }} style={{ color: "#fff" }}>Wyloguj się</ButtonSmall>
                        </div>
                    </div>

                </div>
            </div> : <div></div>}
        </React.Fragment>
    )
}

export default Dashboard